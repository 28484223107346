import {useState} from 'react';
import {IconTool} from '@gisatcz/ptr-atoms';
import ModalWindow from '../../../../../../../common/ModalWindow';
import DonutChart from '../../../../../../../common/charts/DonutChart';
import {
	TableBody,
	TableCell,
	TableCellIcon,
	TableHead,
	TableHeaderCell,
	TableHeaderRow,
	TableRow,
	Table,
} from '../../../../../../../common/Table';

const counts = [
	{
		id: 'a',
		label: 'výrazně nižší obsah uhlíku',
		value: 849,
		color: 'rgb(214,47,39)',
	},
	{
		id: 'b',
		label: 'nižší obsah uhlíku',
		value: 1299,
		color: 'rgb(245,152,105)',
	},
	{
		id: 'c',
		label: 'stejný obsah uhlíku',
		value: 2538,
		color: 'rgb(255,255,191)',
	},
	{
		id: 'd',
		label: 'vyšší obsah uhlíku',
		value: 574,
		color: 'rgb(162,180,189)',
	},
	{
		id: 'e',
		label: 'výrazně vyšší obsah uhlíku',
		value: 56,
		color: 'rgb(69,117,181)',
	},
];

const sum = 5307;

const IndexObsahuUhliku = () => {
	const [modalIsOpen, setModalOpen] = useState(false);
	return (
		<div className="eo4ams-Charts">
			<div className="eo4ams-Charts-header">
				<h4>Relativní obsah uhlíku</h4>
				<IconTool
					className="eo4ams-Charts-headerInfo"
					icon={'ri-info'}
					onClick={() => setModalOpen(true)}
				/>
			</div>
			<span className="eo4ams-Charts-shortDescription">
				Představuje výsledek srovnání aktuální hodnoty SOC na dané ZP s
				hodnotou, která je obvyklá pro příslušný půdní typ.
			</span>
			<div className="eo4ams-Charts-section chart-table">
				<div className="eo4ams-ChartWrapper" style={{height: '12rem'}}>
					<DonutChart
						data={counts.map(item => {
							return {...item, value: item.value / sum};
						})}
						withoutLegend
						margin={{top: 10, right: 30, bottom: 10, left: 10}}
					/>
				</div>
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Kategorie</TableHeaderCell>
								<TableHeaderCell rightAlign>Počet půd. bloků</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{counts.map(item => (
								<TableRow key={item.id}>
									<TableCell>
										<TableCellIcon color={item.color} />
										{item.label}
									</TableCell>
									<TableCell rightAlign>{item.value}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
			<ModalWindow
				title="Relativní obsah uhlíku"
				isOpen={modalIsOpen}
				onClose={() => setModalOpen(false)}
				className="eo4ams-Modal"
			>
				<Table>
					<TableHead>
						<TableHeaderRow>
							<TableHeaderCell>Název markeru</TableHeaderCell>
							<TableHeaderCell rightAlign>Anomálie obsahu SOC</TableHeaderCell>
						</TableHeaderRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Prostorová jednotka</TableCell>
							<TableCell rightAlign>Pixel 20 m/zemědělská parcela</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Referenční období</TableCell>
							<TableCell rightAlign>3 - 5 let</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Frekvence opakování</TableCell>
							<TableCell rightAlign>3 - 5 let</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Kategorizace markeru</TableCell>
							<TableCell rightAlign>Příznakový</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Zdrojová data</TableCell>
							<TableCell rightAlign>Sentinel-2, Landsat</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring stavu ZP</TableCell>
							<TableCell rightAlign>
								Poměr aktuální hodnoty obsahu SOC vůči hodnotě obvyklé pro
								odpovídající půdní typ
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring jevu na ZP</TableCell>
							<TableCell rightAlign>
								Vývoj relativního obsahu půdního uhlíku
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Způsob výpočtu markeru</TableCell>
							<TableCell rightAlign>Statistická analýza</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Typ analýzy markeru</TableCell>
							<TableCell rightAlign></TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</ModalWindow>
		</div>
	);
};

export default IndexObsahuUhliku;
