import classnames from 'classnames';
import './style.scss';
import PropTypes from 'prop-types';

export const TableCellIcon = ({color}) => {
	return <div className="ptr-TableCellIcon" style={{background: color}} />;
};
TableCellIcon.propTypes = {
	color: PropTypes.string,
};

export const TableCell = ({children, rightAlign}) => {
	const classes = classnames('ptr-TableCell', {'is-right': rightAlign});
	return <td className={classes}>{children}</td>;
};
TableCell.propTypes = {
	children: PropTypes.node,
	rightAlign: PropTypes.bool,
};

export const TableHeaderCell = ({children, rightAlign}) => {
	const classes = classnames('ptr-TableHeaderCell', {
		'is-right': rightAlign,
	});
	return <th className={classes}>{children}</th>;
};
TableHeaderCell.propTypes = {
	children: PropTypes.node,
	rightAlign: PropTypes.bool,
};

export const TableRow = ({className, children}) => {
	const classes = classnames('ptr-TableRow', {}, className);
	return <tr className={classes}>{children}</tr>;
};
TableRow.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export const TableHeaderRow = ({children}) => {
	return <TableRow className="ptr-TableHeader">{children}</TableRow>;
};
TableHeaderRow.propTypes = {
	children: PropTypes.node,
};

export const TableBody = ({children}) => {
	return <thead className="ptr-Table-body">{children}</thead>;
};
TableBody.propTypes = {
	children: PropTypes.node,
};

export const TableHead = ({children}) => {
	return <thead className="ptr-Table-head">{children}</thead>;
};
TableHead.propTypes = {
	children: PropTypes.node,
};

export const Table = ({children}) => {
	return <table className="ptr-Table">{children}</table>;
};
Table.propTypes = {
	children: PropTypes.node,
};

export default Table;
