import {Select} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';
import {find as _find, includes as _includes} from 'lodash';

import configuration from './configuration/selectors';
import permanentGrassland from './permanentGrassland/selectors';
import markersData from './MarkersData/selectors';
import common from './common/selectors';

import {casesGetter, cases as casesConfigs} from '../../constants/app';

// helpers
const getStyleConfigName = (config, activeStyleKey) => {
	const {order, byKey} = config;
	if (order) {
		let name = null;
		order.forEach(styleKey => {
			let nameFound = null;
			const style = byKey?.[styleKey];
			if (style?.configForStyles) {
				nameFound = getStyleConfigName(style?.configForStyles, activeStyleKey);
			} else if (styleKey === activeStyleKey) {
				nameFound = style?.name;
			}

			if (nameFound) {
				name = nameFound;
			}
		});
		return name;
	} else {
		return null;
	}
};

// selectors
const getCasesForSelect = state => {
	const cases =
		Select.cases.getIndexed(
			state,
			casesGetter.filterByActive,
			casesGetter.filter,
			casesGetter.order,
			casesGetter.start,
			casesGetter.length
		) || [];
	const localConfiguration = Select.app.getCompleteConfiguration(state);
	const casesForSelect = {};
	const caseLinks = localConfiguration?.caseLinks || {};

	for (const caseConfig of Object.values(casesConfigs)) {
		const c = cases.find(x => x.data.nameInternal === caseConfig.key);
		const caseLink = caseLinks[c.key] || {};
		casesForSelect[c.key] = {
			...caseConfig,
			beKey: c.key,
			data: {
				...caseConfig.data,
				...caseLink,
				nameDisplay: c.data?.nameDisplay,
			},
		};
	}
	return casesForSelect;
};

const getCaseByUrlPath = (state, urlPath) => {
	const cases =
		Select.cases.getIndexed(
			state,
			casesGetter.filterByActive,
			casesGetter.filter,
			casesGetter.order,
			casesGetter.start,
			casesGetter.length
		) || [];
	const caseConfig = Object.values(casesConfigs).find(
		c => c.data.urlName === urlPath
	);
	const caseConfidKey = caseConfig?.key;
	return cases.find(c => c.data.nameInternal === caseConfidKey) || null;
};

const getActiveCaseGroup = createSelector(
	[
		Select.cases.getActiveKey,
		state => Select.app.getConfiguration(state, 'casesGroups'),
	],
	(activeCaseKey, casesGroups) => {
		if (activeCaseKey && casesGroups) {
			return _find(casesGroups, caseGroup =>
				_includes(caseGroup.cases, activeCaseKey)
			);
		} else {
			return null;
		}
	}
);

const isCaseWithoutTabs = createSelector(
	[
		Select.cases.getActiveKey,
		state => Select.app.getConfiguration(state, 'caseLinks'),
	],
	(activeCaseKey, caseLinks) => {
		return caseLinks[activeCaseKey]?.withoutTabs;
	}
);

const getDefaultMapStyleForCase = createSelector(
	[
		state => Select.app.getConfiguration(state, 'caseLinks'),
		Select.styles.getAllAsObject,
		(state, caseKey) => caseKey,
	],
	(caseLinks, styles, caseKey) => {
		const styleKey = caseLinks?.[caseKey]?.configForStyles?.order?.[0];
		return styles?.[styleKey] || null;
	}
);

const getActiveCaseStylesConfig = createSelector(
	[Select.cases.getActiveKey, Select.app.getCompleteConfiguration],
	(activeCaseKey, configuration) => {
		return configuration?.caseLinks?.[activeCaseKey]?.configForStyles || null;
	}
);

const getStylesForActiveCase = createSelector(
	[Select.styles.getAllAsObject, getActiveCaseStylesConfig],
	(styles, stylesConfig) => {
		if (styles && stylesConfig) {
			const {order, byKey} = stylesConfig;
			return order.map(styleKey => {
				const styleMetadata = styles[styleKey];
				const config = byKey[styleKey];
				return {
					...styleMetadata,
					config,
				};
			});
		} else {
			return null;
		}
	}
);

const getStylesForStyle = createSelector(
	[
		Select.styles.getAllAsObject,
		getActiveCaseStylesConfig,
		(state, styleKey) => styleKey,
	],
	(styles, stylesConfig, styleKey) => {
		if (styles && stylesConfig) {
			const {byKey} = stylesConfig;
			const configForStyles = byKey?.[styleKey]?.configForStyles;
			if (configForStyles) {
				const {order, byKey} = configForStyles;
				return order.map(styleKey => {
					const styleMetadata = styles[styleKey];
					const config = byKey[styleKey];
					return {
						...styleMetadata,
						config,
					};
				});
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getThematicLayer = createSelector(
	[
		(state, mapSetKey) => Select.maps.getMapSetActiveMap(state, mapSetKey),
		common.getActiveCaseConfiguration,
	],
	(activeMap, activeCaseConfig) => {
		const activeLayerTemplateKey = activeCaseConfig?.layerTemplateKey;
		if (activeLayerTemplateKey && activeMap) {
			const layer = _find(
				activeMap.data?.layers,
				layer => layer.layerTemplateKey === activeLayerTemplateKey
			);
			return layer || null;
		} else {
			return null;
		}
	}
);

const getThematicLayerStyle = createSelector(
	[getThematicLayer, Select.styles.getAllAsObject],
	(thematicLayer, styles) => {
		if (styles && thematicLayer) {
			const styleKey = thematicLayer?.styleKey;
			if (styleKey) {
				return styles[styleKey] || null;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getActiveStyleKey = createSelector(
	[getThematicLayerStyle, common.getActiveCaseConfiguration],
	(thematicLayerStyle, activeCaseConfig) => {
		if (thematicLayerStyle && activeCaseConfig) {
			return thematicLayerStyle?.key || null;
		} else {
			return null;
		}
	}
);

const getChartsNameForActiveStyle = createSelector(
	[getThematicLayerStyle, common.getActiveCaseConfiguration],
	(style, activeCaseConfig) => {
		if (style && activeCaseConfig) {
			return getStyleConfigName(activeCaseConfig?.configForStyles, style.key);
		} else {
			return null;
		}
	}
);

const getAtributeByAttributeNameInternal = createSelector(
	[
		Select.attributes.getAllAsObject,
		(state, attributeNameInternal) => attributeNameInternal,
	],
	(attributes, attributeNameInternal) => {
		if (attributes && attributeNameInternal) {
			return (
				Object.values(attributes).find(
					attribute => attribute.data.nameInternal === attributeNameInternal
				) || null
			);
		} else {
			return null;
		}
	}
);

export default {
	getCasesForSelect,
	getCaseByUrlPath,
	getActiveCaseGroup,
	isCaseWithoutTabs,

	getStylesForActiveCase,
	getStylesForStyle,
	getDefaultMapStyleForCase,
	getActiveStyleKey,

	getChartsNameForActiveStyle,

	getThematicLayer,
	getThematicLayerStyle,

	configuration,
	permanentGrassland,
	markersData,
	getAtributeByAttributeNameInternal,
};
