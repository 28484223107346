import {utils} from '@gisatcz/ptr-utils';

export const appKey = 'mzecrEo4ams21';

export const mapSetKey = 'map-set';

export const gaId = 'UA-59584085-17';

export const photoLayerKey = 'photoLayer';

export const cases = {
	'trvale-travni-porosty': {
		key: 'case_sece-pastvy',
		data: {
			urlName: 'trvale-travni-porosty',
		},
	},
	'zemedelske-porosty': {
		key: 'case_zemedelske-porosty',
		data: {
			urlName: 'zemedelske-porosty',
		},
	},
	'stav-pudy': {
		key: 'case_stav-pudy',
		data: {
			urlName: 'stav-pudy',
		},
	},
	'pudni-fond': {
		key: 'case_pudni-fond',
		data: {
			urlName: 'pudni-fond',
		},
	},
	'v3-zemedelske-porosty': {
		key: 'case_v3',
		data: {
			urlName: 'v3-zemedelske-porosty',
		},
	},
	'v4-trvale-travni-porosty': {
		key: 'case_v4',
		data: {
			urlName: 'v4-trvale-travni-porosty',
		},
	},
	'v5-stav-pudy': {
		key: 'case_v5',
		data: {
			urlName: 'v5-stav-pudy',
		},
	},
	'v7-stav-pudy': {
		key: 'case_v7',
		data: {
			urlName: 'v7-stav-pudy',
		},
	},
};

export const casesGetter = {
	filterByActive: {
		application: true,
	},
	filter: {},
	order: null,
	start: 1,
	length: 10,
	componentId: `appCases_${utils.uuid()}`,
};

export const attributesGetter = {
	filterByActive: {
		application: true,
	},
	filter: {},
	order: null,
	start: 1,
	length: 1000,
	componentId: `appPeriods_${utils.uuid()}`,
};
