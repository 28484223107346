import ActionTypes from '../../../constants/ActionTypes';

const INITIAL_STATE = {
	byCaseKey: {},
};

const addByCaseKey = (state, caseKey, features) => {
	return {
		...state,
		byCaseKey: {
			...state.byCaseKey,
			[caseKey]: features,
		},
	};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.EO4AMS.MARKERS_DATA.ADD_BY_CASE:
			return addByCaseKey(state, action.caseKey, action.features);
		default:
			return state;
	}
};
