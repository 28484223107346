import {config as getConfig} from '@gisatcz/ptr-core';
import {Action as CommonAction, commonActions} from '@gisatcz/ptr-state';
import eo4amsActions from './eo4ams/actions';
import {appKey, attributesGetter, casesGetter} from '../constants/app';
import attributeSets from '../data/attributeSets.json';
import ActionTypes from '../constants/ActionTypes';

require('dotenv').config();

const getAppEnvConfig = () => {
	if (process?.env) {
		const apiBackendProtocol = process.env?.REACT_APP_apiBackendProtocol;
		const apiBackendHost = process.env?.REACT_APP_apiBackendHost;
		const apiBackendPath = process.env?.REACT_APP_apiBackendPath;
		const requestPageSize = process.env?.REACT_APP_requestPageSize;
		const requestPageSizeXX = process.env?.REACT_APP_requestPageSizeXX;

		return {
			...(apiBackendProtocol ? {apiBackendProtocol} : {}),
			...(apiBackendHost ? {apiBackendHost} : {}),
			...(apiBackendPath ? {apiBackendPath} : {}),
			...(requestPageSize ? {requestPageSize} : {}),
			...(requestPageSizeXX ? {requestPageSizeXX} : {}),
		};
	} else {
		return {};
	}
};

function init(path) {
	return dispatch => {
		dispatch(CommonAction.app.setBaseUrl(path));

		const config = getConfig(getAppEnvConfig());
		dispatch(CommonAction.app.updateLocalConfiguration(config));
		dispatch(CommonAction.app.setKey(appKey));

		//add attributeSets
		// TODO load them from BE
		dispatch(commonActions.add(ActionTypes.ATTRIBUTE_SETS)(attributeSets));

		// add selection and set it active
		// TODO from BE
		dispatch(
			CommonAction.selections.add([
				{
					key: 'polygonSelection',
					data: {
						style: {
							outlineColor: '#00fffe',
							outlineWidth: 5,
						},
					},
				},
				{
					key: 'points',
					data: {
						featureKeysFilter: {
							keys: [],
						},
						style: {
							outlineColor: '#00ff22',
							outlineWidth: 5,
						},
					},
				},
			])
		);
		dispatch(CommonAction.selections.setActiveKey('polygonSelection'));

		return Promise.all([
			//load cases
			dispatch(
				CommonAction.cases.useIndexed(
					casesGetter.filterByActive,
					casesGetter.filter,
					casesGetter.order,
					casesGetter.start,
					casesGetter.length,
					casesGetter.componentId
				)
			),

			//load attributes
			dispatch(
				CommonAction.attributes.useIndexed(
					attributesGetter.filterByActive,
					attributesGetter.filter,
					attributesGetter.order,
					attributesGetter.start,
					attributesGetter.length,
					attributesGetter.componentId
				)
			),

			//load configuration
			dispatch(CommonAction.app.loadConfiguration()),
		]);
	};
}

export default {
	...CommonAction,
	init,
	eo4ams: {
		...eo4amsActions,
	},
};
