import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		activeCaseGroup: Select.eo4ams.getActiveCaseGroup(state),
	};
};

export default connect(mapStateToProps)(Presentation);
