import PropTypes from 'prop-types';
import {Icon} from '@gisatcz/ptr-atoms';
import './style.scss';

const CaseCard = ({title, hasIcon}) => {
	return (
		<div className="eo4ams-CaseCard">
			<h3 className="eo4ams-CaseCard-title">{title}</h3>
			{hasIcon && <Icon icon="back" />}
		</div>
	);
};

CaseCard.propTypes = {
	title: PropTypes.string,
	hasIcon: PropTypes.bool,
};

export default CaseCard;
