import {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {PantherSelect, PantherSelectItem} from '@gisatcz/ptr-atoms';
import {getRouter} from '../../../../../router';
import Logo from '../../../../Logo';
import Introduction from './Introduction';
import Footer from './Footer';
import CaseCard from './CaseCard';
import CaseCardsGroup from './CaseCardsGroup';
import Markers from './About/Markers';
import Project from './About/Project';

import './style.scss';

// FIXME
// separate logic
const onLinkClick = (caseKey, noTabKey) => {
	const router = getRouter();
	const url = router.pathFor('case', {
		case: caseKey,
		...(!noTabKey && {tabKey: 'visualization'}),
	}); // => /hello/John
	router.nav(url); // opens page `/hello/John` that shows alert `Hello John!`
};

const setRouterToRoot = () => {
	const router = getRouter();
	const url = router.pathFor('home');
	router.nav(url);
};

const getCaseByKey = (cases, caseKey) => {
	return caseKey ? Object.values(cases).find(c => c.key === caseKey) : null;
};

const getCaseByBEKey = (cases, caseKey) => {
	return caseKey ? Object.values(cases).find(c => c.beKey === caseKey) : null;
};

const CaseSelect = ({
	onCaseSelect,
	activeCaseKey,
	cases = {},
	casesGroups,
	isOpen,
	currentSection,
}) => {
	const [isInitialLoad, setInitialLoad] = useState(true);
	const [, setCloseable] = useState(true);
	const activeCaseKeyRef = useRef();
	const activeCase = getCaseByBEKey(cases, activeCaseKey);

	useEffect(() => {
		if (activeCaseKeyRef.current !== activeCaseKey) {
			if (!activeCaseKey) {
				setCloseable(false);
			} else {
				setCloseable(true);
			}
			activeCaseKeyRef.current = activeCaseKey;
		}
	}, [activeCaseKey]);

	const renderCurrent = () => {
		if (activeCaseKey && activeCase) {
			return (
				<div className="eo4ams-CaseSelect-current-value">
					<Logo light />
					<h2>{activeCase.data.nameDisplay}</h2>
				</div>
			);
		} else {
			//no indicator
			return (
				<span className="eo4ams-CaseSelect-current-placeholder">
					Select view...
				</span>
			);
		}
	};

	useEffect(() => {
		setInitialLoad(false);
	}, []);

	const onCaseCardClick = caseKey => {
		const _case = getCaseByKey(cases, caseKey);
		onCaseSelect();
		onLinkClick(_case.data.urlName, _case.data.withoutTabs);
	};

	const sectionContent = () => {
		if (currentSection === 'markers') {
			return cardsContent;
		} else if (currentSection === 'project') {
			return <Project />;
		} else {
			return (
				<>
					{cardsContent}
					<Introduction />
				</>
			);
		}
	};

	const cardsContent = casesGroups?.map(group => {
		let content = () => {
			if (currentSection === 'markers') {
				return <Markers />;
			} else {
				return group.cases?.map(caseKey => {
					const c = cases[caseKey];
					if (c) {
						return (
							<PantherSelectItem
								className="eo4ams-CaseCard-wrapper"
								itemKey={c.key}
								key={c.key}
								disabled={c.data.disabled}
							>
								<CaseCard
									title={c.data.nameDisplay}
									hasIcon={!c.data.disabled}
								/>
							</PantherSelectItem>
						);
					} else {
						return null;
					}
				});
			}
		};
		return (
			<CaseCardsGroup
				key={group.title}
				title={group.title}
				currentSection={currentSection}
			>
				{content()}
			</CaseCardsGroup>
		);
	});

	// TODO refactor onSelect, move to actions

	if (!isInitialLoad) {
		return (
			<PantherSelect
				className="eo4ams-CaseSelect"
				open={isOpen}
				onSelectClick={setRouterToRoot}
				onSelect={onCaseCardClick}
				currentClasses="eo4ams-CaseSelect-current"
				renderCurrent={renderCurrent}
				listClasses="eo4ams-CaseSelect-list"
			>
				<div className="eo4ams-CaseSelect-content">
					<div className="eo4ams-Title">
						<Logo />
						<h1>
							Podpora implementace národního Systému monitorování plochy pomocí
							metod DPZ
						</h1>
					</div>
					{sectionContent()}
					<Footer />
				</div>
			</PantherSelect>
		);
	} else {
		return null;
	}
};

CaseSelect.propTypes = {
	activeCaseKey: PropTypes.string,
	cases: PropTypes.object,
	casesGroups: PropTypes.array,
	onUnmount: PropTypes.func,
	onCaseSelect: PropTypes.func,
	isOpen: PropTypes.bool,
	currentSection: PropTypes.string,
};

export default CaseSelect;
