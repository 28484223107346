import PropTypes from 'prop-types';
import DonutChart from '../../../../../../../../common/charts/DonutChart';
import {
	Table,
	TableRow,
	TableHeaderRow,
	TableCell,
	TableHeaderCell,
	TableCellIcon,
	TableHead,
	TableBody,
} from '../../../../../../../../common/Table';

const Semaphore = ({counts, areas}) => {
	return (
		<div className="eo4ams-Charts">
			<h4>Distribuce DPB podle počtu</h4>
			<div className="eo4ams-Charts-section chart-table">
				<div className="eo4ams-ChartWrapper" style={{height: '12rem'}}>
					<DonutChart
						data={counts}
						withoutLegend
						margin={{top: 10, right: 30, bottom: 10, left: 10}}
					/>
				</div>
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Kategorie</TableHeaderCell>
								<TableHeaderCell rightAlign>Počet</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{counts.map(item => (
								<TableRow key={item.id}>
									<TableCell>
										<TableCellIcon color={item.color} />
										{item.label}
									</TableCell>
									<TableCell rightAlign>{item.count}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
			<h4>Distribuce DPB podle výměry</h4>
			<div className="eo4ams-Charts-section chart-table">
				<div className="eo4ams-ChartWrapper" style={{height: '12rem'}}>
					<DonutChart
						data={areas}
						withoutLegend
						margin={{top: 10, right: 30, bottom: 10, left: 10}}
					/>
				</div>
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Kategorie</TableHeaderCell>
								<TableHeaderCell rightAlign>Výměra (ha)</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{areas.map(item => (
								<TableRow key={item.id}>
									<TableCell>
										<TableCellIcon color={item.color} />
										{item.label}
									</TableCell>
									<TableCell rightAlign>
										{item.count.toLocaleString()}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
		</div>
	);
};

Semaphore.propTypes = {
	counts: PropTypes.array,
	areas: PropTypes.array,
};

export default Semaphore;
