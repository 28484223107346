import Header from './Header';
import SidePanel from './SidePanel';
import Map from './Map';

import './style.scss';

const AppContent = () => {
	return (
		<div className={'eo4ams-App'}>
			<Header />
			<div className={'eo4ams-AppContent'}>
				<SidePanel />
				<Map />
			</div>
		</div>
	);
};

export default AppContent;
