import {createSelector} from 'reselect';

const getSubstate = state => state.eo4ams.markersData;
const getAllAsObject = state => state.eo4ams.markersData.byCaseKey;

const getByKey = createSelector(
	[getAllAsObject, (state, key) => key],
	(data, caseKey) => {
		return data?.[caseKey] || null;
	}
);

export default {
	getSubstate,
	getByKey,
};
