import {connect, setRecomputeState} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../../state/Action';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	setRecomputeState(state);
	return {
		activeCase: Select.cases.getActive(state),
		attributesGroupedBySet:
			Select.eo4ams.permanentGrassland.getAttributesGroupedBySet(
				ownProps.componentKey
			),
		thematicLayerStyle: Select.eo4ams.getThematicLayerStyle(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return () => {
		return {
			onMount: () => {
				dispatch(
					Action.attributes.useIndexed(
						null,
						{},
						null,
						1,
						1000,
						ownProps.componentKey
					)
				);
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
