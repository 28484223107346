import {useEffect, useState} from 'react';
import ImageGallery from 'react-image-gallery';
import {useAnalytics} from 'use-analytics';
import propTypes from 'prop-types';
import Checkbox from '../../../../../common/Checkbox';
import './style.scss';

const DpbPhotos = ({
	photos,
	onDisplayPhotosPointsChanged,
	onPhotoSelect,
	capturePointsDisplayed,
}) => {
	const {track} = useAnalytics();

	const [photoFullscreen, setPhotoFullscreen] = useState(false);

	useEffect(() => {
		const photo = photos[0];
		if (typeof onPhotoSelect === 'function') {
			onPhotoSelect(photo.id);
		}
	}, [photos]);

	const onDisplayCapturePointsClick = () => {
		if (typeof onDisplayPhotosPointsChanged === 'function') {
			onDisplayPhotosPointsChanged(!capturePointsDisplayed);
		}
	};

	const onSlide = photoIndex => {
		track('changePhoto');

		const photo = photos[photoIndex];

		if (typeof onPhotoSelect === 'function') {
			onPhotoSelect(photo.id);
		}
	};

	return (
		<div className="eo4ams-dpb-photos">
			<div className="eo4ams-dpb-photos-info">
				<Checkbox
					onChange={onDisplayCapturePointsClick}
					checked={capturePointsDisplayed}
					name="Zobrazit místa pořízení fotografií"
				/>
			</div>
			<ImageGallery
				items={photos}
				showPlayButton={false}
				onSlide={onSlide}
				infinite={false}
				lazyLoad={true}
				thumbnailPosition={photoFullscreen ? 'bottom' : 'right'}
				onScreenChange={setPhotoFullscreen}
			/>
		</div>
	);
};

DpbPhotos.propTypes = {
	capturePointsDisplayed: propTypes.bool,
	onDisplayPhotosPointsChanged: propTypes.func,
	onPhotoSelect: propTypes.func,
	photos: propTypes.array,
};

export default DpbPhotos;
