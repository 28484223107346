import PropTypes from 'prop-types';
import HolaPuda from './/HolaPuda';

const classes = [
	{
		id: 'a',
		label: '< -0.35',
		color: 'rgb(0,97,0)',
	},
	{
		id: 'b',
		label: '-0.35 – -0.2',
		color: 'rgb(122,171,0)',
	},
	{
		id: 'c',
		label: '-0.19 – -0.05',
		color: 'rgb(255,255,0)',
	},
	{
		id: 'd',
		label: '-0.04 – 0.1',
		color: 'rgb(255,153,0)',
	},
	{
		id: 'e',
		label: '> 0.1',
		color: 'rgb(255,34,0)',
	},
];

const countsOption04 = {
	a: 268,
	b: 706,
	c: 952,
	d: 1170,
	e: 2211,
};

const countsOption05 = {
	a: 912,
	b: 1040,
	c: 957,
	d: 1327,
	e: 1071,
};

const countsOption06 = {
	a: 2405,
	b: 1279,
	c: 912,
	d: 591,
	e: 120,
};

const countsOption07 = {
	a: 429,
	b: 1195,
	c: 1823,
	d: 1683,
	e: 177,
};

const countsOption08 = {
	a: 464,
	b: 536,
	c: 625,
	d: 1246,
	e: 2436,
};

const countsOption09 = {
	a: 274,
	b: 617,
	c: 689,
	d: 1608,
	e: 2119,
};

const getClasses = counts => {
	let total = 0;
	Object.values(counts).forEach(item => (total += item));

	return classes.map(item => {
		const count = counts[item.id];
		return {
			...item,
			count,
			value: count / total,
		};
	});
};

const HolaPudaIndex = ({name}) => {
	let counts;

	switch (name) {
		case 'v5-hola-puda-04':
			counts = getClasses(countsOption04);
			break;
		case 'v5-hola-puda-05':
			counts = getClasses(countsOption05);
			break;
		case 'v5-hola-puda-06':
			counts = getClasses(countsOption06);
			break;
		case 'v5-hola-puda-07':
			counts = getClasses(countsOption07);
			break;
		case 'v5-hola-puda-08':
			counts = getClasses(countsOption08);
			break;
		case 'v5-hola-puda-09':
			counts = getClasses(countsOption09);
			break;
	}

	return <HolaPuda counts={counts} />;
};

HolaPudaIndex.propTypes = {
	name: PropTypes.string,
};

export default HolaPudaIndex;
