import {useState} from 'react';
import {IconTool} from '@gisatcz/ptr-atoms';
import ModalWindow from '../../../../../../../common/ModalWindow';
import DonutChart from '../../../../../../../common/charts/DonutChart';
import {
	TableBody,
	TableCell,
	TableCellIcon,
	TableHead,
	TableHeaderCell,
	TableHeaderRow,
	TableRow,
	Table,
} from '../../../../../../../common/Table';
import StackedBarChart from '../../../../../../../common/charts/StackedBarChart';

const counts = [
	{
		id: 'a',
		label: '0-1 měsíc',
		value: 2067,
		color: 'rgb(40,146,199)',
	},
	{
		id: 'b',
		label: '2-3 měsíce',
		value: 803,
		color: 'rgb(160,194,155)',
	},
	{
		id: 'c',
		label: '4-6 měsíců',
		value: 1801,
		color: 'rgb(250,250,100)',
	},
	{
		id: 'd',
		label: '7-8 měsíců',
		value: 473,
		color: 'rgb(250,141,52)',
	},
	{
		id: 'e',
		label: '9-11 měsíců',
		value: 163,
		color: 'rgb(232,16,20)',
	},
];

const sum = 5307;

const labels = {
	value: 'Počet',
};

const data = [
	{
		name: 'leden - březen',
		value: 2343,
	},
	{
		name: 'duben',
		value: 2182,
	},
	{
		name: 'květen',
		value: 1158,
	},
	{
		name: 'červen',
		value: 247,
	},
	{
		name: 'červenec',
		value: 26,
	},
	{
		name: 'srpen',
		value: 896,
	},
	{
		name: 'září',
		value: 1272,
	},
	{
		name: 'říjen - prosinec',
		value: 1233,
	},
];

const colorsDef = {};
data.forEach(item => (colorsDef[item.name] = '#5AB1DD'));

const VyskytHolePudyNaParcelePocet = () => {
	const [modalIsOpen, setModalOpen] = useState(false);
	return (
		<div className="eo4ams-Charts">
			<div className="eo4ams-Charts-header">
				<h4>Výskyt holé půdy na parcele (počet měsíců)</h4>
				<IconTool
					className="eo4ams-Charts-headerInfo"
					icon={'ri-info'}
					onClick={() => setModalOpen(true)}
				/>
			</div>
			<span className="eo4ams-Charts-shortDescription">
				Představuje výsledek detekce přítomnosti holé půdy na ZP.
			</span>
			<div className="eo4ams-Charts-section chart-table">
				<div className="eo4ams-ChartWrapper" style={{height: '12rem'}}>
					<DonutChart
						data={counts.map(item => {
							return {...item, value: item.value / sum};
						})}
						withoutLegend
						margin={{top: 10, right: 30, bottom: 10, left: 10}}
					/>
				</div>
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Počet měsíců</TableHeaderCell>
								<TableHeaderCell rightAlign>
									Počet půdních bloků
								</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{counts.map(item => (
								<TableRow key={item.id}>
									<TableCell>
										<TableCellIcon color={item.color} />
										{item.label}
									</TableCell>
									<TableCell rightAlign>{item.value}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
			<h4>Výskyt holé půdy na parcele (počet parcel v měsíci)</h4>
			<div className="eo4ams-Charts-section full-width">
				<div className="eo4ams-ChartWrapper" style={{height: '12rem'}}>
					<StackedBarChart
						data={data}
						keys={['value']}
						indexBy="name"
						labelsDef={labels}
						colorsDef={colorsDef}
						horizontal
						margin={{top: 0, right: 10, bottom: 0, left: 100}}
						valueFormat={' >-'}
					/>
				</div>
			</div>
			<div className="eo4ams-Charts-section">
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Měsíc</TableHeaderCell>
								<TableHeaderCell rightAlign>
									Počet půdních bloků
								</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{data.map(item => (
								<TableRow key={item.id}>
									<TableCell>{item.name}</TableCell>
									<TableCell rightAlign>{item.value}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
			<ModalWindow
				title="Výskyt holé půdy na parcele (počet měsíců)"
				isOpen={modalIsOpen}
				onClose={() => setModalOpen(false)}
				className="eo4ams-Modal"
			>
				<Table>
					<TableHead>
						<TableHeaderRow>
							<TableHeaderCell>Název markeru</TableHeaderCell>
							<TableHeaderCell rightAlign>
								Výskyt holé půdy na ZP
							</TableHeaderCell>
						</TableHeaderRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Prostorová jednotka</TableCell>
							<TableCell rightAlign>Zemědělská parcela</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Referenční období</TableCell>
							<TableCell rightAlign>
								Datum pořízení snímku / monitorovací perioda
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Frekvence opakování</TableCell>
							<TableCell rightAlign>
								Podle dostupnosti snímku / monitorovací periody
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Kategorizace markeru</TableCell>
							<TableCell rightAlign>Časový</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Zdrojová data</TableCell>
							<TableCell rightAlign>Sentinel-2, Landsat</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring stavu ZP</TableCell>
							<TableCell rightAlign>Výskyt holé půdy na ZP</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring jevu na ZP</TableCell>
							<TableCell rightAlign>
								Dlouhodobý výskyt holé půdy na ZP
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Způsob výpočtu markeru</TableCell>
							<TableCell rightAlign>
								Zpracování časové řady indexu holé půdy
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Typ analýzy markeru</TableCell>
							<TableCell rightAlign>Statistická analýza</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</ModalWindow>
		</div>
	);
};

export default VyskytHolePudyNaParcelePocet;
