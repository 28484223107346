import PropTypes from 'prop-types';
import {useState} from 'react';
import {IconTool} from '@gisatcz/ptr-atoms';
import ModalWindow from '../../../../../../../../common/ModalWindow';
import DonutChart from '../../../../../../../../common/charts/DonutChart';
import {
	Table,
	TableRow,
	TableHeaderRow,
	TableCell,
	TableHeaderCell,
	TableCellIcon,
	TableHead,
	TableBody,
} from '../../../../../../../../common/Table';

const NDVI = ({counts}) => {
	const [modalIsOpen, setModalOpen] = useState(false);

	return (
		<div className="eo4ams-Charts">
			<div className="eo4ams-Charts-header">
				<h4>Vegetační index NDVI</h4>
				<IconTool
					className="eo4ams-Charts-headerInfo"
					icon={'ri-info'}
					onClick={() => setModalOpen(true)}
				/>
			</div>
			<span className="eo4ams-Charts-shortDescription">
				Hodnoty vegetačního indexu NDVI, který je v rámci plodinové klasifikace
				využíván jako jeden z příznaků, a dále pak při identifikaci přítomnosti
				porostu na ZP.
			</span>
			<div className="eo4ams-Charts-section chart-table">
				<div className="eo4ams-ChartWrapper" style={{height: '12rem'}}>
					<DonutChart
						data={counts}
						withoutLegend
						margin={{top: 10, right: 30, bottom: 10, left: 10}}
					/>
				</div>
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Kategorie</TableHeaderCell>
								<TableHeaderCell rightAlign>
									Počet půdních bloků
								</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{counts.map(item => (
								<TableRow key={item.id}>
									<TableCell>
										<TableCellIcon color={item.color} />
										{item.label}
									</TableCell>
									<TableCell rightAlign>{item.count}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
			<ModalWindow
				title="Vegetační index NDVI"
				isOpen={modalIsOpen}
				onClose={() => setModalOpen(false)}
				className="eo4ams-Modal"
			>
				<Table>
					<TableHead>
						<TableHeaderRow>
							<TableHeaderCell>Název markeru</TableHeaderCell>
							<TableHeaderCell rightAlign>Vegetační index NDVI</TableHeaderCell>
						</TableHeaderRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Prostorová jednotka</TableCell>
							<TableCell rightAlign>
								Pixel 10m / zemědělská parcela / část zemědělské parcely
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Referenční období</TableCell>
							<TableCell rightAlign>
								Datum pořízení snímku / monitorovací perioda
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Frekvence opakování</TableCell>
							<TableCell rightAlign>
								Podle dostupnosti snímku / monitorovací periody
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Kategorizace markeru</TableCell>
							<TableCell rightAlign>Příznakový</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Zdrojová data</TableCell>
							<TableCell rightAlign>Sentinel-2, Landsat</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring stavu ZP</TableCell>
							<TableCell rightAlign>
								Stav ZP (přítomnost vegetačního porostu)
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring jevu na ZP</TableCell>
							<TableCell rightAlign>Vývoj porostu, sklizeň plodiny</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Způsob výpočtu markeru</TableCell>
							<TableCell rightAlign>Kombinace spektrálních pásem</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Typ analýzy markeru</TableCell>
							<TableCell rightAlign>
								Prahování, statistická analýza trendu
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</ModalWindow>
		</div>
	);
};

NDVI.propTypes = {
	counts: PropTypes.array,
};

export default NDVI;
