const About = () => {
	return (
		<div className="eo4ams-About-Markers">
			<div>
				<p style={{marginTop: '0'}}>
					Za účelem zvýšení transparentnosti rozhodovacích procesů v rámci AMS
					byl navržen koncept tzv. markerů. Markery reprezentují odvozenou nebo
					interpretovanou informaci vycházející ze zpracování a analýzy
					družicových dat. Každý marker přispívá k získání potřebné tematické
					informace o výskytu a stavu různých druhů zemědělských kultur a plodin
					či o provedených zemědělských opatřeních na sledované zemědělské
					parcele. Tento přístup je pro uživatele, tedy platební agentury a
					příjemce dotací mnohem srozumitelnější a usnadňuje tak komunikaci o
					zjištění na parcele.
				</p>
				<p>
					Koncept markerů umožňuje operativní rozhodování o splnění podmínek
					vybraných dotačních opatření bez nutnosti provádět komplexní datové
					analýzy na straně uživatele markerů. Každý marker popisuje vybraný
					konkrétní tematický aspekt stavu zemědělského pozemku a vývoje jeho
					porostu (např. typ pěstované plodiny, termín sklizně nebo přítomnost
					vegetačního pokryvu). Hodnota markeru je zpravidla odvozena z
					konkrétního družicového snímku (nebo časové řady snímků) a je
					doprovázena sestavou metadat, popisujících zdroj a spolehlivost
					získané tematické informace, časovou platnost a referenční období.
				</p>
				<p style={{marginTop: '2rem'}}>
					<b>
						Markery je možné rozdělit do několika typů podle toho, jakou
						informaci o stavu a vývoji zemědělského porostu popisují:
					</b>
				</p>
				<ul style={{marginLeft: '2rem'}}>
					<li>
						Příznakové markery <br /> Jsou zpravidla odvozeny z jednotlivých
						družicových snímků a popisují vývoj zemědělského porostu na pozemku
						v průběhu celého vegetačního období (např. vegetační index, radarová
						odrazivost).
					</li>
					<li>
						Tematické markery <br /> Jsou zpravidla výsledkem analýzy časové
						řady družicových snímků a popisují vlastnosti zemědělského porostu
						(např. typ zemědělské plodiny).
					</li>
					<li>
						Časové markery <br /> Jsou zpravidla výsledkem analýzy časové řady
						družicových snímků a popisují termín provedení různých zemědělských
						opatření (např. termín seče travního porostu nebo sklizně zemědělské
						plodiny).
					</li>
					<li>
						Statistické markery <br /> Zpravidla popisují pomocí vybraných
						statistických ukazatelů stav zemědělského porostu nebo spolehlivost
						určení tematických nebo časových markerů (např. střední odchylka
						vegetačního indexu na zemědělské parcele nebo pravděpodobnost určení
						zemědělské plodiny).
					</li>
				</ul>
				<p style={{marginTop: '2rem'}}>
					<b>
						Každý marker je popsán v samostatné metadatové tabulce, která
						obsahuje podrobné technické informace o:
					</b>
				</p>
				<ul style={{marginLeft: '2rem'}}>
					<li>typu markeru</li>
					<li>prostorovém a časovém rozlišení</li>
					<li>
						stavech zemědělské parcely a jevech na ní, které marker popisuje
					</li>
					<li>metodách použitých pro výpočet markeru</li>
					<li>
						možnostech analýz markeru pro mapování a monitoring zemědělských
						porostů na orné půdě
					</li>
				</ul>
				<p style={{marginTop: '2rem'}}>
					Všechny markery jsou spočítány pro společné pilotní území, pro které
					jsou připraveny specializované mapy s odborným obsahem.
				</p>
			</div>
		</div>
	);
};

export default About;
