import {connect, setRecomputeState} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import {mapSetKey, photoLayerKey} from '../../../../../constants/app';
import Actions from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const componentKey = 'DpbDetail';

const mapStateToProps = state => {
	setRecomputeState(state);
	const mapSetActiveMapKey = Select.maps.getMapSetActiveMapKey(
		state,
		mapSetKey
	);
	const capturePointsDisplayed = Select.maps.getLayerStateByLayerKeyAndMapKey(
		state,
		mapSetActiveMapKey,
		photoLayerKey
	);

	return {
		capturePointsDisplayed: !!capturePointsDisplayed,

		componentKey,
		attributesMetadata: Select.attributes.getAll(state),
		mowingGrazingAttributes:
			Select.eo4ams.permanentGrassland.getMowingGrazingAttributes(componentKey),
		photos:
			Select.eo4ams.permanentGrassland.getPhotosForSelectedDpb(componentKey),
		selectedFeatureKey:
			Select.eo4ams.permanentGrassland.getSelectedFeatureKey(state),
	};
};

const mapDispatchToProps = dispatch => {
	return () => {
		return {
			onSelectedFeatureChange: () => {
				dispatch(Actions.selections.clearFeatureKeysFilter('points'));
				dispatch(
					Action.eo4ams.permanentGrassland.useDataForDpbDetail(componentKey)
				);
			},

			onDisplayPhotosPointsChanged: displayPhotoLayer => {
				dispatch(
					Actions.eo4ams.permanentGrassland.togglePhotoLayer(displayPhotoLayer)
				);
			},
			onPhotoSelect: featureId => {
				dispatch(
					Actions.eo4ams.permanentGrassland.selectPoint(photoLayerKey, [
						featureId,
					])
				);
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
