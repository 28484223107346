import PropTypes from 'prop-types';
import {MapSet, PresentationMap, DeckGlMap} from '@gisatcz/ptr-maps';
import {connects} from '@gisatcz/ptr-state';
import MapContainer from './MapContainer';
import MapComponentsGroup from './MapComponentsGroup';
import BackgroundLayersControl from './BackgroundLayersControl';
import ZoomControls from './ZoomControls';
import MapAttribution from './MapAttribution';
import Scale from './Scale';
import MapTooltip from './MapTooltip';

import './style.scss';

const ConnectedMap = MapContainer(PresentationMap);
const ConnectedMapSet = connects.MapSet(MapSet);

const Tooltip = props => {
	return <MapTooltip {...props}></MapTooltip>;
};

const Map = ({mapSetKey, viewLimits}) => {
	return (
		<ConnectedMapSet
			Tooltip={Tooltip}
			stateMapSetKey={mapSetKey}
			mapComponent={DeckGlMap}
			connectedMapComponent={ConnectedMap}
		>
			<MapComponentsGroup
				className="eo4ams-MapInfoElements"
				key={'MapComponentsGroup_1'}
			>
				<MapComponentsGroup
					className="eo4ams-AttributionScaleContainer"
					key={'MapComponentsGroup_11'}
				>
					<Scale key={'Scale'} />
					<MapAttribution mapSetKey={mapSetKey} key={'MapAttribution'} />
				</MapComponentsGroup>
			</MapComponentsGroup>
			<MapComponentsGroup
				className="eo4ams-MapSetControls"
				key={'MapComponentsGroup'}
			>
				<BackgroundLayersControl
					mapSetKey={mapSetKey}
					key={'BackgroundLayersControl'}
				/>
				<ZoomControls key={'ZoomControls'} viewLimits={viewLimits} />
			</MapComponentsGroup>
		</ConnectedMapSet>
	);
};

Map.propTypes = {
	mapSetKey: PropTypes.string,
	viewLimits: PropTypes.string,
};

export default Map;
