import {useEffect} from 'react';
import propTypes from 'prop-types';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import Legend from '../../../../../../common/Legend';

import '../style.scss';

const CaseStyleOptions = ({
	title,
	activeStyleKey,
	styles,
	onMount,
	onSelect,
}) => {
	useEffect(() => {
		if (onMount) {
			onMount();
		}
	}, []);

	const options = [];
	let legendName = null;
	let styleDefinitions = null;

	if (styles) {
		styles.forEach(style => {
			const isActive = activeStyleKey === style.key;
			if (isActive) {
				legendName = style?.config.name;
				styleDefinitions = style?.data?.definition?.rules?.[0]?.styles;
			}

			options.push(
				<ButtonSwitchOption key={style.key} value={style.key} active={isActive}>
					{style?.config?.title || style?.data?.nameDisplay}
				</ButtonSwitchOption>
			);
		});
	}

	return (
		<div className="eo4ams-CaseStyle">
			<span className="eo4ams-CaseStyle-label">{title}</span>
			<ButtonSwitch
				className="eo4ams-CaseStyle-switch"
				onClick={onSelect}
				ghost
			>
				{options}
			</ButtonSwitch>
			<Legend name={legendName} styleDefinitions={styleDefinitions} />
		</div>
	);
};

CaseStyleOptions.propTypes = {
	activeStyleKey: propTypes.string,
	onMount: propTypes.func,
	onSelect: propTypes.func,
	styles: propTypes.array,
	title: propTypes.string,
};

export default CaseStyleOptions;
