import {create as createRouter} from '@gisatcz/ptr-router';
import Action from './state/Action';
import Select from './state/Select';
import analytics from './utils/analytics';

// base styles need to be imported before all components
import '@gisatcz/ptr-core/lib/styles/reset.css';
import '@gisatcz/ptr-core/lib/styles/base.scss';
import './styles/index.scss';

let router;

const getStoreFromRequest = request => {
	// store is inside request under Symbol "store"
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol#symbols_and_for...in_iteration
	return request[Object.getOwnPropertySymbols(request)[0]];
};

function createRoutes() {
	return {
		'': {
			name: 'home',
			handler: request => {
				analytics.page({
					path: '/',
					title: 'home',
				});

				// Case is not selected yet
				// User must choose which case he want to explore

				const store = getStoreFromRequest(request);
				const dispatch = store.dispatch;

				//set component
				// dispatch(Action.components.remove('activeScreen', 'urlPlaceKey'));

				//if active place key exist, it needs to override
				const mapSetKey = Select.maps.getActiveSetKey(store.getState());
				dispatch(Action.maps.mapSetUseClear(mapSetKey));
				const mapsInUse = Select.maps.getAllMapsInUse(store.getState());
				mapsInUse?.forEach(mapKey => {
					dispatch(Action.maps.mapUseClear(mapKey));
				});

				dispatch(Action.cases.setActiveKey(null));
			},
		},
		'/about': {
			name: 'about',
			handler: request => {
				const sectionName = request?.match?.pathParams?.section;

				analytics.page({
					path: '/about',
					title: sectionName,
				});
			},
		},
		'/:case': {
			name: 'case',
			handler: request => {
				// Url targets to specific case/scope
				const store = getStoreFromRequest(request);
				const dispatch = store.dispatch;

				// get case key by caseName
				const caseName = request?.match?.pathParams?.case;

				const caseByUrl = Select.eo4ams.getCaseByUrlPath(
					store.getState(),
					caseName
				);
				const newCaseKey = caseByUrl?.key;

				const activeCaseKey = Select.cases.getActiveKey(store.getState());

				if (newCaseKey !== activeCaseKey || (!activeCaseKey && caseName)) {
					analytics.page({
						path: '/caseName',
						title: caseName,
					});
					dispatch(Action.eo4ams.setActiveCaseByCaseUrl(caseName));
				}
			},
		},
	};
}

/**
 * Creates router instance that can be used to manipulate urls.
 *
 * App handler updates store with current page and it's up to views to react to the change.
 * In case of url change, redux selector possibly retrieves different data and passes them
 * into some the component.
 *
 */
const initRouter = (absPath, currentUrl, Store, isPreloaded, navHandler) => {
	router = createRouter({
		rootUrl: absPath,
		currentUrl,
		routes: createRoutes(),
		navHandler,
		store: Store,
		generateUrlsOptions: {
			stringifyQueryParams: params => new URLSearchParams(params).toString(),
		},
	});

	return router;
};

const getRouter = () => router;

export {getRouter, initRouter};
