import PropTypes from 'prop-types';
import {useMemo} from 'react';
import App from '../pages/App';

const getActiveScreenComponent = page => {
	if (page?.name) {
		return <App />;
	} else {
		return null;
	}
	// switch (page?.name) {
	// 	case 'case':
	// 		return <App />;
	// 	default:
	// 		return <Unknown />;
	// }
};

const Router = ({page}) => {
	const ActiveScreenComponent = useMemo(() => {
		return getActiveScreenComponent(page);
	}, [page]);

	return <>{ActiveScreenComponent}</>;
};

Router.propTypes = {
	page: PropTypes.object,
};

export default Router;
