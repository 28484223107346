import PropTypes from 'prop-types';
import Semaphore from '../Semaphore/Semaphore';

import './style.scss';

const classes = [
	{
		id: 'noMonitoring',
		label: 'Nesplňuje podmínky pro monitoring',
		color: '#888888',
	},
	{
		id: 'noManagement',
		label: 'Nezjištěn žádný management',
		color: '#c48e5e',
	},
	{
		id: 'green',
		label: 'Zelená kategorie',
		color: '#4daf4a',
	},
	{
		id: 'red',
		label: 'Červená kategorie',
		color: '#e41a1c',
	},
	{
		id: 'orange',
		label: 'Oranžová kategorie',
		color: '#ff7f00',
	},
];

const countsOptionA = {
	noMonitoring: 500,
	noManagement: 262,
	green: 4878,
	red: 1053,
	orange: 74,
};

const areaOptionA = {
	noMonitoring: 62,
	noManagement: 823,
	green: 14374,
	red: 2848,
	orange: 181,
};

const countsOptionB = {
	noMonitoring: 500,
	noManagement: 262,
	green: 4162,
	red: 1053,
	orange: 790,
};

const areaOptionB = {
	noMonitoring: 62,
	noManagement: 823,
	green: 10779,
	red: 2848,
	orange: 3776,
};

const countsOptionC = {
	noMonitoring: 500,
	noManagement: 262,
	green: 1920,
	red: 401,
	orange: 3684,
};

const areaOptionC = {
	noMonitoring: 62,
	noManagement: 823,
	green: 6443,
	red: 1314,
	orange: 9645,
};

const getClasses = counts => {
	let total = 0;
	Object.values(counts).forEach(item => (total += item));

	return classes.map(item => {
		const count = counts[item.id];
		return {
			...item,
			count,
			value: count / total,
		};
	});
};

const SemaphoreWrapper = ({name}) => {
	let counts;
	let areas;

	switch (name) {
		case 'classificationSemaphore-optionA':
			counts = getClasses(countsOptionA);
			areas = getClasses(areaOptionA);
			break;
		case 'classificationSemaphore-optionB':
			counts = getClasses(countsOptionB);
			areas = getClasses(areaOptionB);
			break;
		case 'classificationSemaphore-optionC':
			counts = getClasses(countsOptionC);
			areas = getClasses(areaOptionC);
			break;
	}

	return <Semaphore counts={counts} areas={areas} />;
};

SemaphoreWrapper.propTypes = {
	name: PropTypes.string,
};

export default SemaphoreWrapper;
