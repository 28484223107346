import {useState} from 'react';
import {IconTool} from '@gisatcz/ptr-atoms';
import ModalWindow from '../../../../../../../common/ModalWindow';
import DonutChart from '../../../../../../../common/charts/DonutChart';
import {
	TableBody,
	TableCell,
	TableCellIcon,
	TableHead,
	TableHeaderCell,
	TableHeaderRow,
	TableRow,
	Table,
} from '../../../../../../../common/Table';

const counts = [
	{
		id: '0',
		label: '0',
		value: 190,
		color: 'rgb(255,0,0)',
	},
	{
		id: '1',
		label: '1',
		value: 1480,
		color: 'rgb(215,255,115)',
	},
	{
		id: '2',
		label: '2',
		value: 310,
		color: 'rgb(122,171,0)',
	},
	{
		id: '3',
		label: '3',
		value: 1,
		color: 'rgb(0,97,0)',
	},
	{
		id: 'nevyhodnoceno',
		label: 'DPB nebylo vyhodnoceno',
		value: 214,
		color: 'rgb(150,150,150)',
	},
];

const sum = 2195;

const PocetSeci = () => {
	const [modalIsOpen, setModalOpen] = useState(false);
	return (
		<div className="eo4ams-Charts">
			<div className="eo4ams-Charts-header">
				<h4>Počet zjištěných sečí v referenčním období</h4>
				<IconTool
					className="eo4ams-Charts-headerInfo"
					icon={'ri-info'}
					onClick={() => setModalOpen(true)}
				/>
			</div>
			<span className="eo4ams-Charts-shortDescription">
				Výsledek aplikace modelu pro monitoring travních porostů (pravidel pro
				detekci sečí) na interpolované profily NDVI, BS a COH.
			</span>
			<div className="eo4ams-Charts-section chart-table">
				<div className="eo4ams-ChartWrapper" style={{height: '12rem'}}>
					<DonutChart
						data={counts.map(item => {
							return {...item, value: item.value / sum};
						})}
						withoutLegend
						margin={{top: 10, right: 30, bottom: 10, left: 10}}
					/>
				</div>
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Počet sečí</TableHeaderCell>
								<TableHeaderCell rightAlign>Počet půd. bloků</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{counts.map(item => (
								<TableRow key={item.id}>
									<TableCell>
										<TableCellIcon color={item.color} />
										{item.label}
									</TableCell>
									<TableCell rightAlign>{item.value}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
			<ModalWindow
				title="Počet zjištěných sečí v referenčním období"
				isOpen={modalIsOpen}
				onClose={() => setModalOpen(false)}
				className="eo4ams-Modal"
			>
				<Table>
					<TableHead>
						<TableHeaderRow>
							<TableHeaderCell>Název markeru</TableHeaderCell>
							<TableHeaderCell rightAlign>Monitoring seče</TableHeaderCell>
						</TableHeaderRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Prostorová jednotka</TableCell>
							<TableCell rightAlign>Zemědělská parcela</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Referenční období</TableCell>
							<TableCell rightAlign>
								Podle požadavků (od začátku vegetačního období)
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Frekvence opakování</TableCell>
							<TableCell rightAlign>Podle požadavků (min. 2 týdny)</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Kategorizace markeru</TableCell>
							<TableCell rightAlign>Časový</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Zdrojová data</TableCell>
							<TableCell rightAlign>Sentinel-1, Sentinel-2, Landsat</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring stavu ZP</TableCell>
							<TableCell rightAlign>
								Stav travního porostu na ZP (posečený / neposečený)
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring jevu na ZP</TableCell>
							<TableCell rightAlign>Seč travního porostu</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Způsob výpočtu markeru</TableCell>
							<TableCell rightAlign>
								Analýza družicových dat pomocí rozhodovacích stromů
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Typ analýzy markeru</TableCell>
							<TableCell rightAlign>
								Potvrzení seče do vybraného termínu
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</ModalWindow>
		</div>
	);
};

export default PocetSeci;
