import PropTypes from 'prop-types';
import HolaPuda from '../HolaPuda/HolaPuda';

const classes = [
	{
		id: 'a',
		label: '< 0,05',
		color: 'rgb(69,117,181)',
	},
	{
		id: 'b',
		label: '0,05 - 0,1',
		color: 'rgb(162,180,189)',
	},
	{
		id: 'c',
		label: '0,11 - 0,15',
		color: 'rgb(255,255,191)',
	},
	{
		id: 'd',
		label: '0,16 - 0,25',
		color: 'rgb(245,152,105)',
	},
	{
		id: 'e',
		label: '>0,25',
		color: 'rgb(214,47,39)',
	},
];

const countsOption04 = {
	a: 2786,
	b: 1898,
	c: 414,
	d: 188,
	e: 21,
};

const countsOption05 = {
	a: 1891,
	b: 2570,
	c: 528,
	d: 265,
	e: 53,
};

const countsOption06 = {
	a: 1017,
	b: 3404,
	c: 555,
	d: 263,
	e: 68,
};

const countsOption07 = {
	a: 1744,
	b: 3131,
	c: 314,
	d: 112,
	e: 6,
};

const countsOption08 = {
	a: 1566,
	b: 3113,
	c: 308,
	d: 215,
	e: 105,
};

const countsOption09 = {
	a: 1943,
	b: 2890,
	c: 265,
	d: 180,
	e: 29,
};

const getClasses = counts => {
	let total = 0;
	Object.values(counts).forEach(item => (total += item));

	return classes.map(item => {
		const count = counts[item.id];
		return {
			...item,
			count,
			value: count / total,
		};
	});
};

const HolaPudaIndex = ({name}) => {
	let counts;

	switch (name) {
		case 'v7-hola-puda-04':
			counts = getClasses(countsOption04);
			break;
		case 'v7-hola-puda-05':
			counts = getClasses(countsOption05);
			break;
		case 'v7-hola-puda-06':
			counts = getClasses(countsOption06);
			break;
		case 'v7-hola-puda-07':
			counts = getClasses(countsOption07);
			break;
		case 'v7-hola-puda-08':
			counts = getClasses(countsOption08);
			break;
		case 'v7-hola-puda-09':
			counts = getClasses(countsOption09);
			break;
	}

	return <HolaPuda counts={counts} />;
};

HolaPudaIndex.propTypes = {
	name: PropTypes.string,
};

export default HolaPudaIndex;
