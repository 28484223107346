import {getRouter} from '../../router';
import Action from '../Action';
import Select from '../Select';
import {casesGetter} from '../../constants/app';
import configuration from './configuration/actions';
import permanentGrassland from './permanentGrassland/actions';
import markersData from './MarkersData/actions';

const updateAppUrl = update => {
	return (dispatch, getState) => {
		const currentParams = Select.router.getCurrent(getState());

		// FIXME
		// separate logic
		const router = getRouter();
		const url = router.pathFor('case', {
			...currentParams?.params?.path,
			...update,
		});
		router.nav(url);
	};
};

const setActiveCaseByCaseUrl = caseUrl => {
	return (dispatch, getState) => {
		//load cases
		dispatch(
			Action.cases.useIndexed(
				casesGetter.filterByActive,
				casesGetter.filter,
				casesGetter.order,
				casesGetter.start,
				casesGetter.length,
				casesGetter.componentId
			)
		).then(() => {
			const caseByUrl = Select.eo4ams.getCaseByUrlPath(getState(), caseUrl);
			const caseKey = caseByUrl?.key;

			if (caseKey) {
				dispatch(Action.cases.setActiveKey(caseKey));

				// set active view
				const localConfiguration = Select.app.getCompleteConfiguration(
					getState()
				);
				const caseLinks = localConfiguration?.caseLinks || {};
				const caseLink = caseLinks[caseKey] || {};
				const caseViewKey = caseLink.viewKey;
				const fileName = caseLink.staticDataFileName;

				dispatch(Action.views.applyAndSetActive(caseViewKey, Action)).then(
					() => {
						const view = Select.views.getActive(getState());
						if (view) {
							dispatch(
								configuration.updateStateFromView(
									view.data.state.eo4ams.configuration
								)
							);

							if (fileName) {
								dispatch(markersData.use({...caseByUrl, ...caseLink}));
							}
						}
					}
				);
			}
		});
	};
};

const setThematicLayerStyle = styleKey => {
	return (dispatch, getState) => {
		const state = getState();
		const mapSetKey = Select.maps.getActiveSetKey(state);
		const thematicLayer = Select.eo4ams.getThematicLayer(state, mapSetKey);

		const activeMapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);

		if (activeMapKey && thematicLayer) {
			dispatch(
				Action.maps.setMapLayerStyleKey(
					activeMapKey,
					thematicLayer.key,
					styleKey
				)
			);

			if (thematicLayer?.type) {
				const style = Select.styles.getByKey(state, styleKey);
				dispatch(
					Action.maps.setMapLayerOption(
						activeMapKey,
						thematicLayer.key,
						'style',
						style.data.definition
					)
				);
			} else {
				dispatch(Action.maps.use(activeMapKey));
			}
		}
	};
};

export default {
	updateAppUrl,
	setActiveCaseByCaseUrl,
	setThematicLayerStyle,

	configuration,
	permanentGrassland,
	markersData,
};
