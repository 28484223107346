import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../state/Select';

const mapStateToProps = state => {
	const activeMapSetKey = Select.maps.getActiveSetKey(state);

	return {
		name: Select.eo4ams.getChartsNameForActiveStyle(state, activeMapSetKey),
	};
};

export default connect(mapStateToProps)(Presentation);
