import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const Checkbox = ({name, onChange, checked, disabled}) => {
	const classes = classnames('ptr-Checkbox', {
		'is-disabled': disabled,
	});

	return (
		<label className={classes}>
			<input
				type="checkbox"
				onChange={onChange}
				checked={checked || false}
				disabled={disabled || false}
			/>
			<span>{name}</span>
		</label>
	);
};

Checkbox.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func,
	checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	disabled: PropTypes.bool,
};

export default Checkbox;
