import classnames from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

const Logo = ({light}) => {
	const classes = classnames('eo4ams-Logo', {
		'is-light': light,
	});

	return (
		<div className={classes}>
			EO<em>4</em>AMS
		</div>
	);
};

Logo.propTypes = {
	light: PropTypes.bool,
};

export default Logo;
