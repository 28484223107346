import {useState} from 'react';
import {IconTool} from '@gisatcz/ptr-atoms';
import ModalWindow from '../../../../../../../common/ModalWindow';
import DonutChart from '../../../../../../../common/charts/DonutChart';
import {
	TableBody,
	TableCell,
	TableCellIcon,
	TableHead,
	TableHeaderCell,
	TableHeaderRow,
	TableRow,
	Table,
} from '../../../../../../../common/Table';

const counts = [
	{
		id: 'a',
		label: '0 %',
		value: 771,
		color: 'rgb(255,235,214)',
	},
	{
		id: 'b',
		label: '0,01 - 1 %',
		value: 1175,
		color: 'rgb(240,188,156)',
	},
	{
		id: 'c',
		label: '1,01 - 2 %',
		value: 1661,
		color: 'rgb(224,132,101)',
	},
	{
		id: 'd',
		label: '2,01 - 3 %',
		value: 1184,
		color: 'rgb(209,71,52)',
	},
	{
		id: 'e',
		label: '> 3 %',
		value: 516,
		color: 'rgb(196,10,10)',
	},
];

const sum = 5307;

const IndexObsahuUhliku = () => {
	const [modalIsOpen, setModalOpen] = useState(false);
	return (
		<div className="eo4ams-Charts">
			<div className="eo4ams-Charts-header">
				<h4>Index obsahu uhlíku</h4>
				<IconTool
					className="eo4ams-Charts-headerInfo"
					icon={'ri-info'}
					onClick={() => setModalOpen(true)}
				/>
			</div>
			<span className="eo4ams-Charts-shortDescription">
				Představuje výsledek aplikace modelu pro kvantitativní odhad obsahu SOC.
			</span>
			<div className="eo4ams-Charts-section chart-table">
				<div className="eo4ams-ChartWrapper" style={{height: '12rem'}}>
					<DonutChart
						data={counts.map(item => {
							return {...item, value: item.value / sum};
						})}
						withoutLegend
						margin={{top: 10, right: 30, bottom: 10, left: 10}}
					/>
				</div>
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Kategorie</TableHeaderCell>
								<TableHeaderCell rightAlign>
									Počet půdních bloků
								</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{counts.map(item => (
								<TableRow key={item.id}>
									<TableCell>
										<TableCellIcon color={item.color} />
										{item.label}
									</TableCell>
									<TableCell rightAlign>{item.value}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
			<ModalWindow
				title="Index obsahu uhlíku"
				isOpen={modalIsOpen}
				onClose={() => setModalOpen(false)}
				className="eo4ams-Modal"
			>
				<Table>
					<TableHead>
						<TableHeaderRow>
							<TableHeaderCell>Název markeru</TableHeaderCell>
							<TableHeaderCell rightAlign>
								Odhad obsahu půdního organického uhlíku (SOC)
							</TableHeaderCell>
						</TableHeaderRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Prostorová jednotka</TableCell>
							<TableCell rightAlign>Pixel 20 m/zemědělská parcela</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Referenční období</TableCell>
							<TableCell rightAlign>3 - 5 let</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Frekvence opakování</TableCell>
							<TableCell rightAlign>3 - 5 let</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Kategorizace markeru</TableCell>
							<TableCell rightAlign>Příznakový</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Zdrojová data</TableCell>
							<TableCell rightAlign>Sentinel-2, Landsat</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring stavu ZP</TableCell>
							<TableCell rightAlign>Obsah půdního uhlíku</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring jevu na ZP</TableCell>
							<TableCell rightAlign></TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Způsob výpočtu markeru</TableCell>
							<TableCell rightAlign>Model pro odhad obsahu uhlíku</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Typ analýzy markeru</TableCell>
							<TableCell rightAlign>Statistická analýza</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</ModalWindow>
		</div>
	);
};

export default IndexObsahuUhliku;
