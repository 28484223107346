import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../../state/Action';
import Select from '../../../../../../state/Select';
import analytics from '../../../../../../utils/analytics';

const mapStateToProps = state => {
	const activeMapSetKey = Select.maps.getActiveSetKey(state);

	return {
		styles: Select.eo4ams.getStylesForActiveCase(state),
		activeStyleKey: Select.eo4ams.getActiveStyleKey(state, activeMapSetKey),
	};
};

const mapDispatchToProps = dispatch => {
	return () => {
		const componentKey = 'CaseStyle';

		return {
			onMount: () => {
				dispatch(
					Action.styles.useIndexed(null, null, null, 1, 100, componentKey)
				);
			},
			onSelect: styleKey => {
				analytics.track('activeStyle', {
					styleKey: styleKey,
				});

				dispatch(Action.eo4ams.setThematicLayerStyle(styleKey));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
