import PropTypes from 'prop-types';
import VectorLayerToolip from './VectorLayerToolip';

const VectorMapTooltip = ({layer}) => {
	const layerKey = layer?.sourceLayer?.props?.layerKey;
	switch (layerKey) {
		case 'layer-v3':
		case 'layer-v4':
		case 'layer-v5':
		case 'layer-v7':
			return (
				<VectorLayerToolip
					properties={layer?.object?.properties}
					style={layer?.layer?.props?.options?.style}
					styleKey={layer?.layer?.props?.styleKey}
					color={layer.sourceLayer.props.getFillColor(layer.object)}
				/>
			);

		default:
			return null;
	}
};

VectorMapTooltip.propTypes = {
	layer: PropTypes.object,
};

export default VectorMapTooltip;
