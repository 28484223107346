import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../state/Select';

import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const style = Select.styles.getByKey(state, ownProps.styleKey);
	const attributeKey =
		style?.data?.definition?.rules?.[0]?.styles?.[1]?.attributeKey;

	const attributeValue = ownProps?.properties?.[attributeKey];
	const attribute = Select.eo4ams.getAtributeByAttributeNameInternal(
		state,
		attributeKey
	);

	const nameValue =
		attributeKey &&
		style?.data?.definition?.rules?.[0]?.styles[1]?.attributeValues
			? style?.data?.definition?.rules?.[0]?.styles[1]?.attributeValues[
					attributeValue
			  ]?.name
			: null;

	return {
		nameDisplay: style.data.nameDisplay,
		attribute,
		attributeValue,
		nameValue,
	};
};

const mapDispatchToProps = () => {
	return () => {
		return {};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
