import PropTypes from 'prop-types';
import classnames from 'classnames';

export const LegendCategory = ({outlineColor, fillColor, label}) => {
	return (
		<div className="eo4ams-LegendCategory">
			<div
				className="eo4ams-LegendCategory-symbol"
				style={{borderColor: outlineColor, background: fillColor}}
			/>
			<div className="eo4ams-LegendCategory-label">{label}</div>
		</div>
	);
};

LegendCategory.propTypes = {
	outlineColor: PropTypes.string,
	fillColor: PropTypes.string,
	label: PropTypes.string,
};

export const LegendCategoryLine = ({color, width, label}) => {
	return (
		<div className="eo4ams-LegendCategory">
			<div
				className="eo4ams-LegendCategory-symbol line"
				style={{height: `${width}px`, background: color}}
			/>
			<div className="eo4ams-LegendCategory-label">{label}</div>
		</div>
	);
};

LegendCategoryLine.propTypes = {
	color: PropTypes.string,
	width: PropTypes.number,
	label: PropTypes.string,
};

export const LegendCategories = ({children, inRow}) => {
	const classes = classnames('eo4ams-LegendCategories', {
		'is-in-row': inRow,
	});

	return <div className={classes}>{children}</div>;
};

LegendCategories.propTypes = {
	inRow: PropTypes.bool,
	children: PropTypes.node,
};
