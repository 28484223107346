import {Action as CommonAction, setRecomputeState} from '@gisatcz/ptr-state';
import request from '../../../utils/request';
import {mapSetKey, photoLayerKey} from '../../../constants/app';
import ActionTypes from '../../../constants/ActionTypes';
import Action from '../../Action';
import Select from '../../Select';

import {photoLayer} from '../../../data/layers/photoLayer';

const togglePhotoLayer = visible => {
	return (dispatch, getState) => {
		const state = getState();
		const activeMapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);
		if (visible) {
			dispatch(Action.maps.addMapLayers(activeMapKey, [photoLayer]));
		} else {
			dispatch(Action.maps.removeMapLayer(activeMapKey, photoLayerKey));
		}
	};
};

const selectPoint = (layerKey, featureKeys) => {
	return (dispatch, getState) => {
		const state = getState();
		const activeMap = Select.maps.getMapSetActiveMap(state, mapSetKey);
		const layer = Select.maps.getLayerStateByLayerKeyAndMapKey(
			state,
			activeMap.key,
			layerKey
		);
		if (layer) {
			dispatch(
				Action.maps.setMapLayerOption(activeMap.key, layerKey, 'selected', {
					points: {keys: featureKeys},
				})
			);
			dispatch(Action.selections.setActiveKey('points'));
			dispatch(
				Action.selections.setActiveSelectionFeatureKeysFilterKeys(featureKeys)
			);
		}
	};
};

const useMowingGrazingAttributes = componentKey => {
	return (dispatch, getState) => {
		const state = getState();
		setRecomputeState(state);

		const mowingGrazingAttributesRepository = Select.app.getConfiguration(
			state,
			'mowingGrazingAttributesRepository'
		);
		const activeCase = Select.cases.getActive(state);
		const dpbCode =
			Select.eo4ams.permanentGrassland.getSelectedFeatureDpbCode(componentKey);
		if (activeCase?.data?.nameInternal === 'case_sece-pastvy' && dpbCode) {
			const regex = /(\/)/g;
			const file = dpbCode.replace(regex, '_');

			// TODO for now, request allways for data
			const url = `${mowingGrazingAttributesRepository}${file}.json`;
			return request(url, 'GET', null, null).then(data => {
				if (data) {
					dispatch(actionAddMowingGrazingAttributesByDpbCode(data));
				}
			});
		}
	};
};

const useDataForDpbDetail = componentKey => {
	return (dispatch, getState) => {
		const state = getState();
		const filter =
			Select.eo4ams.permanentGrassland.getFilterForAttributesListUse(state);
		if (filter) {
			dispatch(
				CommonAction.data.components.updateComponent(componentKey, filter)
			);
			dispatch(CommonAction.data.components.use(componentKey)).then(() => {
				dispatch(useMowingGrazingAttributes(componentKey));
			});
		}
	};
};

const actionAddMowingGrazingAttributesByDpbCode = data => {
	return {
		type: ActionTypes.EO4AMS.PERMANENT_GRASSLAND
			.ADD_MOWING_GRAZING_ATTRIBUTES_BY_DPB_CODE,
		data,
	};
};

export default {
	selectPoint,
	togglePhotoLayer,
	useDataForDpbDetail,
};
