import PropTypes from 'prop-types';
import {forIn as _forIn} from 'lodash';
import {LegendCategory, LegendCategories} from './components';

const StyleBasedLegend = ({styleDefinitions}) => {
	const [defaultStyle, attributeStyle] = styleDefinitions;

	const content = [];
	if (attributeStyle) {
		const {attributeValues, attributeClasses} = attributeStyle;
		if (attributeValues) {
			_forIn(attributeValues, (item, value) => {
				content.push(
					<LegendCategory
						key={value}
						fillColor={item.fill}
						label={item.name || value}
					/>
				);
			});
		}

		if (attributeClasses) {
			attributeClasses.forEach(item => {
				content.push(
					<LegendCategory
						key={item.interval}
						fillColor={item.fill}
						label={item.name || `${item.interval[0]} - ${item.interval[1]}`}
					/>
				);
			});
		}
	}

	content.push(
		<LegendCategory
			key="default"
			fillColor={defaultStyle.fill}
			label={defaultStyle.name}
		/>
	);

	return (
		<LegendCategories inRow={content.length > 6}>{content}</LegendCategories>
	);
};

StyleBasedLegend.propTypes = {
	styleDefinitions: PropTypes.array,
};

export default StyleBasedLegend;
