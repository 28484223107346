import PropTypes from 'prop-types';
import CaseStyle from './CaseStyle';
import Charts from './Charts';

import './style.scss';

const CaseOverview = ({activeCaseGroup}) => {
	return (
		<div className="eo4ams-CaseOverview">
			<div className="eo4ams-CaseOverview-header">
				<CaseStyle
					title={
						activeCaseGroup?.key === 'pilots'
							? 'Vyberte téma'
							: 'Vyberte marker'
					}
					optionsTitle={
						activeCaseGroup?.key === 'pilots'
							? 'Vyberte variantu'
							: 'Vyberte měsíc'
					}
				/>
			</div>
			<Charts />
		</div>
	);
};

CaseOverview.propTypes = {
	activeCaseGroup: PropTypes.object,
};

export default CaseOverview;
