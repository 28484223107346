import {connect} from '@gisatcz/ptr-state';
import Select from '../../state/Select';

import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		page: Select.router.getCurrent(state),
	};
};

export default connect(mapStateToProps)(Presentation);
