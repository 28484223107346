import PropTypes from 'prop-types';
import {getRouter} from '../../../../../../router';
import {Icon} from '@gisatcz/ptr-atoms';

import './style.scss';

const CaseCardsGroup = ({children, title, currentSection}) => {
	const setRouterToAboutMarkers = () => {
		const router = getRouter();
		if (currentSection === 'markers') {
			let url = router.pathFor('home');
			router.nav(url);
		} else {
			let url = router.pathFor('about', {
				section: 'markers',
			});
			router.nav(url);
		}
	};

	return (
		<>
			{title !== 'Pilotní aplikace' ? (
				<div className="eo4ams-CaseCardsGroup">
					<div className="eo4ams-CaseCardsGroup-title">
						<h2 className="eo4ams-CaseCardsGroup-titleText">{title} </h2>
						<a
							className="eo4ams-CaseCardsGroup-titleInfo"
							onClick={() => setRouterToAboutMarkers()}
						>
							<Icon
								icon={currentSection === 'markers' ? 'ri-back' : 'ri-info'}
							/>
						</a>
					</div>
					<div className="eo4ams-CaseCards">{children}</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

CaseCardsGroup.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	currentSection: PropTypes.string,
};

export default CaseCardsGroup;
