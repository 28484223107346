import {getRouter} from '../../../../../router';

const Introduction = () => {
	const setRouterToAboutProject = () => {
		const router = getRouter();
		const url = router.pathFor('about', {
			section: 'project',
		});
		router.nav(url);
	};
	return (
		<div className="eo4ams-About-ProjectIntroduction">
			<div>
				<p>
					Projekt{' '}
					<i>
						Vývoj a ověření nástrojů pro podporu implementace národního Systému
						monitorování plochy pomocí metod Dálkového průzkumu Země
					</i>{' '}
					je řešen v rámci{' '}
					<a
						className="eo4ams-About-link"
						href="https://eagri.cz/public/web/file/467922/_2._Program_2017_2025_ZEME.pdf"
						target="_blank"
						rel="noreferrer noopener"
					>
						Programu aplikovaného výzkumu Ministerstva zemědělství na období
						2017 - 2025 (ZEMĚ)
					</a>
					. Aktivity projektu jsou realizovány ve spolupráci tří partnerů:{' '}
					<a
						className="eo4ams-About-link"
						href="https://www.czechglobe.cz/cs/"
						target="_blank"
						rel="noreferrer noopener"
					>
						Ústav výzkumu globální změny AV ČR
					</a>{' '}
					(hlavní řešitel),{' '}
					<a
						className="eo4ams-About-link"
						href="http://gisat.cz/"
						target="_blank"
						rel="noreferrer noopener"
					>
						GISAT s.r.o.
					</a>{' '}
					a{' '}
					<a
						className="eo4ams-About-link"
						href="https://www.ekotoxa.cz/"
						target="_blank"
						rel="noreferrer noopener"
					>
						EKOTOXA s.r.o.
					</a>{' '}
					(další účastníci projektu).{' '}
					<a
						className="eo4ams-About-linkInfo"
						onClick={() => setRouterToAboutProject()}
					>
						Více informací...
					</a>
				</p>
			</div>
		</div>
	);
};

export default Introduction;
