import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = state => {
	const mapSetKey = Select.maps.getActiveSetKey(state);
	return {
		mapSetKey,
		viewLimits: Select.maps.getMapSetViewLimits(state, mapSetKey),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
