import CaseSelect from './CaseSelect';

import './style.scss';

const Header = () => {
	return (
		<div className="eo4ams-Header ptr-dark">
			<CaseSelect />
		</div>
	);
};

export default Header;
