import {Icon} from '@gisatcz/ptr-atoms';
import {getRouter} from '../../../../../../../router';

const About = () => {
	const setRouterToHome = () => {
		const router = getRouter();
		const url = router.pathFor('home');
		router.nav(url);
	};

	return (
		<div className="eo4ams-About-Project">
			<div>
				<p>
					<a
						className="eo4ams-About-infoBack"
						onClick={() => setRouterToHome()}
					>
						<Icon icon={'ri-back'} />
					</a>
					Projekt{' '}
					<i>
						Vývoj a ověření nástrojů pro podporu implementace národního Systému
						monitorování plochy pomocí metod Dálkového průzkumu Země
					</i>{' '}
					je řešen v rámci{' '}
					<a
						className="eo4ams-About-link"
						href="https://eagri.cz/public/web/file/467922/_2._Program_2017_2025_ZEME.pdf"
						target="_blank"
						rel="noreferrer noopener"
					>
						Programu aplikovaného výzkumu Ministerstva zemědělství na období
						2017 - 2025 (ZEMĚ)
					</a>
					. Aktivity projektu jsou realizovány ve spolupráci tří partnerů:{' '}
					<a
						className="eo4ams-About-link"
						href="https://www.czechglobe.cz/cs/"
						target="_blank"
						rel="noreferrer noopener"
					>
						Ústav výzkumu globální změny AV ČR
					</a>{' '}
					(hlavní řešitel),{' '}
					<a
						className="eo4ams-About-link"
						href="http://gisat.cz/"
						target="_blank"
						rel="noreferrer noopener"
					>
						GISAT s.r.o.
					</a>{' '}
					a{' '}
					<a
						className="eo4ams-About-link"
						href="https://www.ekotoxa.cz/"
						target="_blank"
						rel="noreferrer noopener"
					>
						EKOTOXA s.r.o.
					</a>{' '}
					(další účastníci projektu).
				</p>
				<p>
					Implementace Systému monitorování plochy (AMS) je jedním z hlavních
					pilířů{' '}
					<a
						className="eo4ams-About-link"
						href="https://ec.europa.eu/info/food-farming-fisheries/key-policies/common-agricultural-policy/cap-glance_cs"
						target="_blank"
						rel="noreferrer noopener"
					>
						Společné zemědělské politiky (SZP)
					</a>{' '}
					pro nové programové období 2021 - 2027. Tento systém je definován jako
					<i>
						postup pravidelného a systematického pozorování, sledování a
						hodnocení zemědělské činnosti a postupů na zemědělských plochách na
						základě satelitních údajů z družic Copernicus Sentinels
					</i>
					.
				</p>
				<p>
					Zavádění AMS do praxe s sebou přináší zásadní změny v oblasti rozsahu
					a způsobu kontrol vyplácení dotačních podpor. Na základě principů
					Kontrol pomocí monitoringu bude prováděno ověření správnosti všech
					podaných dotačních žádostí s využitím automatizovaných metod
					vyhodnocení družicových dat v režimu operačního a průběžného
					monitoringu stavu a vývoje zemědělských porostů v průběhu celého
					vegetačního období.
				</p>
				<p>
					Pravidla dotačních podpor v rámci nové SZP doznávají značných změn
					oproti předchozímu programovacímu období také zavedením nových
					intervencí, zejména pak v rámci Režimů pro klima a životní prostředí
					(tzv. Ekoschémat). Jedná se například o podpory zaměřené na pěstování
					meziplodin, zvyšování obsahu organické hmoty v půdě, údržbu trvalých
					travních porostů či omezení používání přípravků na ochranu rostlin v
					blízkosti vod. Kontrola realizace těchto podpor je zcela zásadní pro
					dosažení vysoké míry jejich efektivnosti a měla by být proto
					uplatňována v co největším rozsahu. To je principiálně možné pouze s
					využitím metod Dálkového průzkumu Země (DPZ), které poskytnou rychlou
					indikaci nedostatků v hospodaření, které mohou být v rozporu s
					pravidly daných opatření.
				</p>
				<p>
					Veškeré procesy a následná rozhodnutí v rámci monitoringu musí být
					spolehlivá, transparentní, odpovědná (verifikovatelná) a
					dokumentovatelná. Při vyhodnocení způsobilosti pomocí analýzy
					družicových dat nelze spolehnout čistě na výstupy automatizovaných
					klasifikačních procesů (např. strojové učení nebo neuronové sítě).
					Techniky strojového učení jsou nicméně zásadní pro hromadné zpracování
					velkých objemů dat družic Sentinel. Důležitým faktorem úspěchu celého
					monitorovacího přístupu je následné převedení výsledků a informací
					získaných pomocí automatizované analýzy družicových dat do formy
					popisu reálných jevů a zjištění, relevantních pro ověření splnění
					vybraných dotačních podmínek a pravidel.
				</p>
			</div>
		</div>
	);
};

export default About;
