import PropTypes from 'prop-types';
import NDVI from '../NDVI/NDVI';

const classes = [
	{
		id: 'a',
		label: '< 0,05',
		color: 'rgb(69,117,181)',
	},
	{
		id: 'b',
		label: '0,05 - 0,1',
		color: 'rgb(162,180,189)',
	},
	{
		id: 'c',
		label: '0,11 - 0,15',
		color: 'rgb(255,255,191)',
	},
	{
		id: 'd',
		label: '0,16 - 0,25',
		color: 'rgb(245,152,105)',
	},
	{
		id: 'e',
		label: '>0,25',
		color: 'rgb(214,47,39)',
	},
];

const countsOption04 = {
	a: 1549,
	b: 2869,
	c: 495,
	d: 312,
	e: 82,
};

const countsOption05 = {
	a: 851,
	b: 3197,
	c: 711,
	d: 386,
	e: 162,
};

const countsOption06 = {
	a: 1482,
	b: 2815,
	c: 585,
	d: 301,
	e: 124,
};

const countsOption07 = {
	a: 1308,
	b: 3320,
	c: 466,
	d: 196,
	e: 17,
};

const countsOption08 = {
	a: 1061,
	b: 3072,
	c: 712,
	d: 310,
	e: 152,
};

const countsOption09 = {
	a: 975,
	b: 3202,
	c: 726,
	d: 309,
	e: 95,
};

const getClasses = counts => {
	let total = 0;
	Object.values(counts).forEach(item => (total += item));

	return classes.map(item => {
		const count = counts[item.id];
		return {
			...item,
			count,
			value: count / total,
		};
	});
};

const NDVIIndex = ({name}) => {
	let counts;

	switch (name) {
		case 'v7-ndvi-04':
			counts = getClasses(countsOption04);
			break;
		case 'v7-ndvi-05':
			counts = getClasses(countsOption05);
			break;
		case 'v7-ndvi-06':
			counts = getClasses(countsOption06);
			break;
		case 'v7-ndvi-07':
			counts = getClasses(countsOption07);
			break;
		case 'v7-ndvi-08':
			counts = getClasses(countsOption08);
			break;
		case 'v7-ndvi-09':
			counts = getClasses(countsOption09);
			break;
	}

	return <NDVI counts={counts} />;
};

NDVIIndex.propTypes = {
	name: PropTypes.string,
};

export default NDVIIndex;
