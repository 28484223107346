import {filter as _filter, flatten as _flatten} from 'lodash';
import moment from 'moment/moment';
import {
	createRecomputeObserver,
	createRecomputeSelector,
	Select,
} from '@gisatcz/ptr-state';
import points from '../../../data/geometries/permanentGrassland/points.json';
import {createSelector} from 'reselect';
import {appKey} from '../../../constants/app';
import common from '../common/selectors';

// Observers
const getActiveCaseObserver = createRecomputeObserver(Select.cases.getActive);
const getCompleteConfigurationObserver = createRecomputeObserver(
	Select.app.getCompleteConfiguration
);
const getAttributesAsObjectObserver = createRecomputeObserver(
	Select.attributes.getAllAsObject
);
const getAttributeSetsObserver = createRecomputeObserver(
	Select.attributeSets.getAll
);

// Selectors
const getSelectedFeatureKey = createSelector(
	[Select.selections.getAllAsObject],
	selections => {
		const selection = selections?.['polygonSelection'];
		if (selection) {
			return selection.data?.featureKeysFilter?.keys?.[0] || null;
		} else {
			return null;
		}
	}
);

const getSelectedFeatureKeyObserver = createRecomputeObserver(
	getSelectedFeatureKey
);

const getActiveCaseAboutKey = createSelector(
	[common.getActiveCaseConfiguration],
	activeCaseConfig => {
		if (activeCaseConfig) {
			return activeCaseConfig?.aboutKey;
		} else {
			return null;
		}
	}
);

const getFilterForAttributesListUse = createSelector(
	[
		getSelectedFeatureKey,
		common.getActiveCaseConfiguration,
		Select.attributeSets.getAll,
		Select.cases.getActiveKey,
		Select.periods.getActiveKey,
	],
	(featureKey, activeCaseConfig, attributeSets, caseKey, periodKey) => {
		if (activeCaseConfig && featureKey && attributeSets) {
			const layerTemplateKey = activeCaseConfig?.layerTemplateKey;
			const attributeKeysCollections = attributeSets.map(
				set => set.data.attributes
			);
			const attributeKeys = _flatten(attributeKeysCollections);
			if (attributeKeys?.length && layerTemplateKey) {
				return {
					metadataModifiers: {
						caseKey,
						applicationKey: appKey,
						periodKey: periodKey,
					},
					layerTemplateKey,
					attributeKeys,
					featureKeys: [featureKey],
					start: 1,
					length: 1,
				};
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getAttributesGroupedBySet = createRecomputeSelector(componentKey => {
	const attributesAsObject = getAttributesAsObjectObserver();
	const attributeSets = getAttributeSetsObserver();
	const selectedFeatureKey = getSelectedFeatureKeyObserver();
	const data = Select.data.components.getData(componentKey);

	const featureData = data?.[0];
	if (
		attributeSets &&
		attributesAsObject &&
		featureData &&
		selectedFeatureKey
	) {
		const {data: properties} = featureData;

		const attributeSetsData = [];
		attributeSets.forEach(attributeSet => {
			const attributesData = [];
			const attributeKeys = attributeSet?.data?.attributes;

			if (attributeKeys) {
				attributeKeys.forEach(key => {
					const attributeMetadata = attributesAsObject[key];
					if (attributeMetadata) {
						const value = properties[key];
						if (value !== undefined) {
							attributesData.push({
								metadata: attributeMetadata,
								value,
							});
						}
					}
				});
			}

			if (attributesData?.length) {
				attributeSetsData.push({
					metadata: attributeSet,
					attributes: attributesData,
				});
			}
		});

		return attributeSetsData?.length ? attributeSetsData : null;
	} else {
		return null;
	}
});

const getSelectedFeatureDpbCode = createRecomputeSelector(componentKey => {
	const configuration = getCompleteConfigurationObserver();
	const attributeData = Select.data.components.getData(componentKey);
	const selectedFeatureData = attributeData?.[0];

	if (selectedFeatureData && configuration) {
		const {dpbCodeAttributeKey} = configuration;
		const {data: selectedFeatureProperties} = selectedFeatureData;

		if (selectedFeatureProperties && dpbCodeAttributeKey) {
			return selectedFeatureProperties[dpbCodeAttributeKey];
		} else {
			return null;
		}
	} else {
		return null;
	}
});

const getPhotosForSelectedDpb = createRecomputeSelector(componentKey => {
	const configuration = getCompleteConfigurationObserver();
	const photoPoints = points.features;

	if (photoPoints && configuration) {
		const {photoThumbnailsUrlPath, photosUrlPath} = configuration;
		const dpbCode = getSelectedFeatureDpbCode(componentKey);
		if (dpbCode) {
			const photoItems = _filter(
				photoPoints,
				point => point.properties?.['NKOD_DPB'] === dpbCode
			);
			if (photoItems?.length) {
				return photoItems.map(item => {
					const {properties} = item;
					const date = properties.Date;
					return {
						id: properties.fid,
						date: properties.Date,
						fullscreen: `${photosUrlPath}${properties.Name}`,
						thumbnail: `${photoThumbnailsUrlPath}${properties.thumb}`,
						original: `${photoThumbnailsUrlPath}${properties.thumb}`,
						name: properties.NKOD_DPB,
						description: moment(date).format('D. M. YYYY'),
					};
				});
			} else {
				return null;
			}
		} else {
			return null;
		}
	} else {
		return null;
	}
});

const getMowingGrazingAttributesByDpbCodeObserver = createRecomputeObserver(
	state => state.eo4ams.permanentGrassland.mowingGrazingAttributesByDpbCode
);

const getMowingGrazingAttributes = createRecomputeSelector(componentKey => {
	const activeCase = getActiveCaseObserver();
	const dpbCode = getSelectedFeatureDpbCode(componentKey);
	const mowingGrazingAttributesByDpbCode =
		getMowingGrazingAttributesByDpbCodeObserver();
	const data = mowingGrazingAttributesByDpbCode?.[dpbCode];

	if (activeCase?.data?.nameInternal === 'case_sece-pastvy' && data) {
		return data;
	} else {
		return null;
	}
});

export default {
	getActiveCaseAboutKey,
	getAttributesGroupedBySet,
	getFilterForAttributesListUse,
	getMowingGrazingAttributes,
	getPhotosForSelectedDpb,
	getSelectedFeatureKey,
	getSelectedFeatureDpbCode,
};
