import DonutChart from '../../../../../../../common/charts/DonutChart';

const dataCount = [
	{
		id: 'sec',
		label: 'Seč',
		value: 0.91,
		color: '#39bded',
	},
	{
		id: 'pastva',
		label: 'Pastva',
		value: 0.01,
		color: '#94e312',
	},
	{
		id: 'combinedManagement',
		label: 'Smíšený management',
		value: 0.04,
		color: '#f0c413',
	},
	{
		id: 'noManagement',
		label: 'Žádný management',
		value: 0.04,
		color: '#c48e5e',
	},
];

const dataArea = [
	{
		id: 'sec',
		label: 'Seč',
		value: 0.79,
		color: '#39bded',
	},
	{
		id: 'pastva',
		label: 'Pastva',
		value: 0.02,
		color: '#94e312',
	},
	{
		id: 'combinedManagement',
		label: 'Smíšený management',
		value: 0.14,
		color: '#f0c413',
	},
	{
		id: 'noManagement',
		label: 'Žádný management',
		value: 0.05,
		color: '#c48e5e',
	},
];

const dataCountManagement = [
	{
		id: 'first',
		label: 'První seč nebo pastva do 31.7.',
		value: 0.82,
		color: '#bcbddc',
	},
	{
		id: 'then',
		label: 'První seč nebo pastva po 31.7.',
		value: 0.18,
		color: '#756bb1',
	},
];

const dataAreaManagement = [
	{
		id: 'first',
		label: 'První seč nebo pastva do 31.7.',
		value: 0.82,
		color: '#bcbddc',
	},
	{
		id: 'then',
		label: 'První seč nebo pastva po 31.7.',
		value: 0.18,
		color: '#756bb1',
	},
];

const dataCountMonths = [
	{
		id: 'may',
		label: 'květen',
		value: 0.24,
		color: '#f2f0f7',
	},
	{
		id: 'june',
		label: 'červen',
		value: 0.43,
		color: '#dadaeb',
	},
	{
		id: 'july',
		label: 'červenec',
		value: 0.15,
		color: '#bcbddc',
	},
	{
		id: 'august',
		label: 'srpen',
		value: 0.07,
		color: '#9e9ac8',
	},
	{
		id: 'september',
		label: 'září',
		value: 0.07,
		color: '#756bb1',
	},
	{
		id: 'october',
		label: 'říjen',
		value: 0.04,
		color: '#54278f',
	},
];

const dataAreaMonths = [
	{
		id: 'may',
		label: 'květen',
		value: 0.27,
		color: '#f2f0f7',
	},
	{
		id: 'june',
		label: 'červen',
		value: 0.41,
		color: '#dadaeb',
	},
	{
		id: 'july',
		label: 'červenec',
		value: 0.14,
		color: '#bcbddc',
	},
	{
		id: 'august',
		label: 'srpen',
		value: 0.06,
		color: '#9e9ac8',
	},
	{
		id: 'september',
		label: 'září',
		value: 0.08,
		color: '#756bb1',
	},
	{
		id: 'october',
		label: 'říjen',
		value: 0.04,
		color: '#54278f',
	},
];

const Management = () => {
	return (
		<div className="eo4ams-Charts">
			<h4>Distribuce podle typu managementu</h4>
			<div className="eo4ams-Charts-section two-columns">
				<div>
					<h5>Podíl podle počtu DPB</h5>
					<div className="eo4ams-ChartWrapper" style={{height: '15rem'}}>
						<DonutChart
							data={dataCount}
							columnLegend
							legendTranslateY={70}
							margin={{top: 15, right: 40, bottom: 100, left: 40}}
						/>
					</div>
				</div>
				<div>
					<h5>Podíl podle výměry DPB</h5>
					<div className="eo4ams-ChartWrapper" style={{height: '15rem'}}>
						<DonutChart
							data={dataArea}
							columnLegend
							legendTranslateY={70}
							margin={{top: 15, right: 40, bottom: 100, left: 40}}
						/>
					</div>
				</div>
			</div>
			<h4>Podíl první seče nebo pastvy do 31. července</h4>
			<div className="eo4ams-Charts-section two-columns">
				<div>
					<h5>Podíl podle počtu DPB</h5>
					<div className="eo4ams-ChartWrapper" style={{height: '13rem'}}>
						<DonutChart
							data={dataCountManagement}
							columnLegend
							margin={{top: 15, right: 40, bottom: 70, left: 40}}
						/>
					</div>
				</div>
				<div>
					<h5>Podíl podle výměry DPB</h5>
					<div className="eo4ams-ChartWrapper" style={{height: '13rem'}}>
						<DonutChart
							data={dataAreaManagement}
							columnLegend
							margin={{top: 15, right: 40, bottom: 70, left: 40}}
						/>
					</div>
				</div>
			</div>
			<h4>Podíl první seče nebo pastvy podle měsíců</h4>
			<div className="eo4ams-Charts-section two-columns">
				<div>
					<h5>Podíl podle počtu DPB</h5>
					<div className="eo4ams-ChartWrapper" style={{height: '16rem'}}>
						<DonutChart
							data={dataCountMonths}
							columnLegend
							legendTranslateY={105}
							margin={{top: 15, right: 40, bottom: 115, left: 40}}
						/>
					</div>
				</div>
				<div>
					<h5>Podíl podle výměry DPB</h5>
					<div className="eo4ams-ChartWrapper" style={{height: '16rem'}}>
						<DonutChart
							data={dataAreaMonths}
							columnLegend
							legendTranslateY={105}
							margin={{top: 15, right: 40, bottom: 115, left: 40}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Management;
