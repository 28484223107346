import {Action as CommonAction} from '@gisatcz/ptr-state';
import config from '../../../config';
import ActionTypes from '../../../constants/ActionTypes';
import request from '../../../utils/request';
import Select from '../../Select';

// ============ creators ===========
function use(caseMetadata) {
	return (dispatch, getState) => {
		const {staticDataFileName, layerKey, key} = caseMetadata;

		return dispatch(ensureDataForMap(key, staticDataFileName))
			.then(() => {
				const state = getState();
				const mapSetKey = Select.maps.getActiveSetKey(state);
				const mapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);
				const layerState = Select.maps.getLayerStateByLayerKeyAndMapKey(
					state,
					mapKey,
					layerKey
				);
				const styleKey = layerState?.styleKey;
				if (styleKey) {
					dispatch(
						CommonAction.styles.useKeys([styleKey], 'MarkersData.use')
					).then(() => {
						dispatch(handleMapLayerData(layerKey));
					});
				}
			})
			.catch(err => {
				throw new Error(err);
			});
	};
}

function handleMapLayerData(layerKey) {
	return (dispatch, getState) => {
		const state = getState();
		const caseKey = Select.cases.getActiveKey(state);
		const features = Select.eo4ams.markersData.getByKey(state, caseKey);
		const style = Select.eo4ams.getDefaultMapStyleForCase(state, caseKey);
		const mapSetKey = Select.maps.getActiveSetKey(state);
		const mapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);

		dispatch(
			CommonAction.maps.setMapLayerOption(
				mapKey,
				layerKey,
				'features',
				features || []
			)
		);

		if (style) {
			dispatch(
				CommonAction.maps.setMapLayerStyleKey(mapKey, layerKey, style.key)
			);
			dispatch(
				CommonAction.maps.setMapLayerOption(
					mapKey,
					layerKey,
					'style',
					style.data.definition
				)
			);
		}
	};
}

function ensureDataForMap(caseKey, fileName) {
	return dispatch => {
		return dispatch(ensureFeatures(caseKey, fileName));
	};
}

function ensureFeatures(caseKey, fileName) {
	return (dispatch, getState) => {
		const url = `${config.staticDataRepository}/${fileName}`;
		const featuresDownloaded = Select.eo4ams.markersData.getByKey(
			getState(),
			caseKey
		);
		if (!featuresDownloaded) {
			return request(url, 'GET', null, null).then(data => {
				if (data) {
					dispatch(actionAddByCase(caseKey, data?.features));
				}
			});
		} else {
			return Promise.resolve();
		}
	};
}

// ============ actions ===========

const actionAddByCase = (caseKey, features) => {
	return {
		type: ActionTypes.EO4AMS.MARKERS_DATA.ADD_BY_CASE,
		caseKey,
		features,
	};
};

// ============ export ===========

export default {
	use,
};
