import PropTypes from 'prop-types';
import NDVI from '../NDVI/NDVI';

const classes = [
	{
		id: 'a',
		label: '< 0,25',
		color: 'rgb(255,34,0)',
	},
	{
		id: 'b',
		label: '0,25 - 0,45',
		color: 'rgb(255,153,0)',
	},
	{
		id: 'c',
		label: '0,46 - 0,65',
		color: 'rgb(255,255,0)',
	},
	{
		id: 'd',
		label: '0,66 - 0,8',
		color: 'rgb(122,171,0)',
	},
	{
		id: 'e',
		label: '>0,8',
		color: 'rgb(0,97,0)',
	},
];

const countsOption04 = {
	a: 2137,
	b: 1078,
	c: 1074,
	d: 868,
	e: 150,
};

const countsOption05 = {
	a: 1119,
	b: 1112,
	c: 1069,
	d: 1239,
	e: 768,
};

const countsOption06 = {
	a: 232,
	b: 547,
	c: 898,
	d: 1807,
	e: 1823,
};

const countsOption07 = {
	a: 22,
	b: 1566,
	c: 2176,
	d: 1128,
	e: 415,
};

const countsOption08 = {
	a: 821,
	b: 2234,
	c: 953,
	d: 554,
	e: 745,
};

const countsOption09 = {
	a: 1231,
	b: 1696,
	c: 1161,
	d: 761,
	e: 458,
};

const getClasses = counts => {
	let total = 0;
	Object.values(counts).forEach(item => (total += item));

	return classes.map(item => {
		const count = counts[item.id];
		return {
			...item,
			count,
			value: count / total,
		};
	});
};

const NDVIIndex = ({name}) => {
	let counts;

	switch (name) {
		case 'v3-ndvi-04':
			counts = getClasses(countsOption04);
			break;
		case 'v3-ndvi-05':
			counts = getClasses(countsOption05);
			break;
		case 'v3-ndvi-06':
			counts = getClasses(countsOption06);
			break;
		case 'v3-ndvi-07':
			counts = getClasses(countsOption07);
			break;
		case 'v3-ndvi-08':
			counts = getClasses(countsOption08);
			break;
		case 'v3-ndvi-09':
			counts = getClasses(countsOption09);
			break;
	}

	return <NDVI counts={counts} />;
};

NDVIIndex.propTypes = {
	name: PropTypes.string,
};

export default NDVIIndex;
