import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import Actions from '../../../../state/Action';

const mapStateToProps = state => {
	const routerState = Select.router.getCurrent(state);
	const activeTabKey = routerState?.params?.parsedQueryString?.tabKey;

	return {
		activeCase: Select.cases.getActiveKey(state),
		activeTabKey: activeTabKey || null,
		withoutTabs: Select.eo4ams.isCaseWithoutTabs(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onTabClick: (evt, key) => {
			dispatch(Actions.eo4ams.updateAppUrl({tabKey: key}));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
