import {useState} from 'react';
import {IconTool} from '@gisatcz/ptr-atoms';
import ModalWindow from '../../../../../../../common/ModalWindow';
import StackedBarChart from '../../../../../../../common/charts/StackedBarChart';
import {
	TableBody,
	TableCell,
	TableCellIcon,
	TableHead,
	TableHeaderCell,
	TableHeaderRow,
	TableRow,
	Table,
} from '../../../../../../../common/Table';

const labels = {
	value: 'Počet',
};

const data = [
	{
		name: 'květen',
		value: 16,
		color: '#5AB1DD',
	},
	{
		name: 'červen',
		value: 910,
		color: '#5AB1DD',
	},
	{
		name: 'červenec',
		value: 757,
		color: '#5AB1DD',
	},
	{
		name: 'srpen',
		value: 338,
		color: '#5AB1DD',
	},
	{
		name: 'září',
		value: 82,
		color: '#5AB1DD',
	},
];

const colorsDef = {};
data.forEach(item => (colorsDef[item.name] = item.color));

const MesicniMonitoringSeci = () => {
	const [modalIsOpen, setModalOpen] = useState(false);

	return (
		<div className="eo4ams-Charts">
			<div className="eo4ams-Charts-header">
				<h4>Měsíční monitoring sečí</h4>
				<IconTool
					className="eo4ams-Charts-headerInfo"
					icon={'ri-info'}
					onClick={() => setModalOpen(true)}
				/>
			</div>
			<span className="eo4ams-Charts-shortDescription">
				Výsledek aplikace modelu pro monitoring travních porostů (pravidel pro
				detekci sečí) na interpolované profily NDVI, BS a COH.
			</span>
			<div className="eo4ams-Charts-section full-width">
				<div className="eo4ams-ChartWrapper" style={{height: '12rem'}}>
					<StackedBarChart
						data={data}
						keys={['value']}
						indexBy="name"
						labelsDef={labels}
						colorsDef={colorsDef}
						horizontal
						margin={{top: 0, right: 10, bottom: 0, left: 100}}
						valueFormat={' >-'}
					/>
				</div>
			</div>
			<div className="eo4ams-Charts-section">
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Měsíc</TableHeaderCell>
								<TableHeaderCell rightAlign>
									Počet půdních bloků
								</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{data.map(item => (
								<TableRow key={item.id}>
									<TableCell>
										<TableCellIcon color={item.color} />
										{item.name}
									</TableCell>
									<TableCell rightAlign>{item.value}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
			<ModalWindow
				title="Měsíční monitoring sečí"
				isOpen={modalIsOpen}
				onClose={() => setModalOpen(false)}
				className="eo4ams-Modal"
			>
				<Table>
					<TableHead>
						<TableHeaderRow>
							<TableHeaderCell>Název markeru</TableHeaderCell>
							<TableHeaderCell rightAlign>Monitoring seče</TableHeaderCell>
						</TableHeaderRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Prostorová jednotka</TableCell>
							<TableCell rightAlign>Zemědělská parcela</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Referenční období</TableCell>
							<TableCell rightAlign>
								Podle požadavků (od začátku vegetačního období)
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Frekvence opakování</TableCell>
							<TableCell rightAlign>Podle požadavků (min. 2 týdny)</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Kategorizace markeru</TableCell>
							<TableCell rightAlign>Časový</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Zdrojová data</TableCell>
							<TableCell rightAlign>Sentinel-1, Sentinel-2, Landsat</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring stavu ZP</TableCell>
							<TableCell rightAlign>
								Stav travního porostu na ZP (posečený / neposečený)
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring jevu na ZP</TableCell>
							<TableCell rightAlign>Seč travního porostu</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Způsob výpočtu markeru</TableCell>
							<TableCell rightAlign>
								Analýza družicových dat pomocí rozhodovacích stromů
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Typ analýzy markeru</TableCell>
							<TableCell rightAlign>
								Potvrzení seče do vybraného termínu
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</ModalWindow>
		</div>
	);
};

export default MesicniMonitoringSeci;
