import PropTypes from 'prop-types';
import {LegendCategories, LegendCategory} from './components';
import './style.scss';
import StyleBasedLegend from './StyleBasedLegend';

const Management = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#39bded"
				outlineColor="#39bded"
				label="Zjištěna seč"
			/>
			<LegendCategory
				fillColor="#94e312"
				outlineColor="#94e312"
				label="Zjištěna pastva"
			/>
			<LegendCategory
				fillColor="#f0c413"
				outlineColor="#f0c413"
				label="Zjištěn smíšený management"
			/>
			<LegendCategory
				fillColor="#c48e5e"
				outlineColor="#c48e5e"
				label="Nezjištěn žádný management"
			/>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="DPB nesplňuje podmínky pro monitoring"
			/>
		</LegendCategories>
	);
};

const NoMonitoringReason = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#ff7f00"
				outlineColor="#ff7f00"
				label="Geometrie DPB nesplňuje podmínky pro monitoring"
			/>
			<LegendCategory
				fillColor="#984ea3"
				outlineColor="#984ea3"
				label="Družicová data nejsou k dispozici nebo je nelze vyhodnotit"
			/>
			<LegendCategory
				fillColor="#e41a1c"
				outlineColor="#e41a1c"
				label="Ostatní důvody"
			/>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="DPB splňuje podmínky pro monitoring"
			/>
		</LegendCategories>
	);
};

const Outlines = () => {
	return (
		<LegendCategories>
			<LegendCategory outlineColor="#f0c85b" label="Hranice půdních bloků" />
		</LegendCategories>
	);
};

const Photos = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#e7298a"
				outlineColor="#e7298a"
				label="Ověřováno v terénu"
			/>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="Neověřováno v terénu"
			/>
		</LegendCategories>
	);
};

const ClassificationSemaphoreOptionA = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="DPB nesplňuje podmínky pro monitoring"
			/>
			<LegendCategory
				fillColor="#c48e5e"
				outlineColor="#c48e5e"
				label="Nezjištěn žádný management"
			/>
			<LegendCategory
				fillColor="#4daf4a"
				outlineColor="#4daf4a"
				label="Zjištěna seč před 29.7. nebo pastva do konce července"
			/>
			<LegendCategory
				fillColor="#e41a1c"
				outlineColor="#e41a1c"
				label="Zjištěna seč po 4.8. nebo pastva od srpna"
			/>
			<LegendCategory
				fillColor="#ff7f00"
				outlineColor="#ff7f00"
				label="Ostatní DPB"
			/>
		</LegendCategories>
	);
};

const ClassificationSemaphoreOptionB = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#c48e5e"
				outlineColor="#c48e5e"
				label="Nezjištěn žádný management"
			/>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="DPB nesplňuje podmínky pro monitoring"
			/>
			<LegendCategory
				fillColor="#4daf4a"
				outlineColor="#4daf4a"
				label="Zjištěna seč před 29.7. (jen na celém pozemku nebo v kombinaci s pastvou) nebo pastva do konce července"
			/>
			<LegendCategory
				fillColor="#e41a1c"
				outlineColor="#e41a1c"
				label="Zjištěna seč po 4.8. nebo pastva od srpna"
			/>
			<LegendCategory
				fillColor="#ff7f00"
				outlineColor="#ff7f00"
				label="Ostatní DPB"
			/>
		</LegendCategories>
	);
};

const ClassificationSemaphoreOptionC = () => {
	return (
		<LegendCategories>
			<LegendCategory
				fillColor="#c48e5e"
				outlineColor="#c48e5e"
				label="Nezjištěn žádný management"
			/>
			<LegendCategory
				fillColor="#aaaaaa"
				outlineColor="#888888"
				label="DPB nesplňuje podmínky pro monitoring"
			/>
			<LegendCategory
				fillColor="#4daf4a"
				outlineColor="#4daf4a"
				label="Zjištěna seč před 29.7. (jen na celém pozemku nebo v kombinaci s pastvou, současně jen s vyšší mírou spolehlivosti) nebo pastva do konce července (jen s vyšší mírou spolehlivosti)  "
			/>
			<LegendCategory
				fillColor="#e41a1c"
				outlineColor="#e41a1c"
				label="Zjištěna seč po 4.8. (jen s vyšší mírou spolehlivosti) nebo pastva od srpna (jen s vyšší mírou spolehlivosti) "
			/>
			<LegendCategory
				fillColor="#ff7f00"
				outlineColor="#ff7f00"
				label="Ostatní DPB"
			/>
		</LegendCategories>
	);
};

const Legend = ({name, styleDefinitions}) => {
	let content;

	switch (name) {
		case 'management':
			content = <Management />;
			break;
		case 'noMonitoringReason-sece':
			content = <NoMonitoringReason />;
			break;
		case 'classificationSemaphore-optionA':
			content = <ClassificationSemaphoreOptionA />;
			break;
		case 'classificationSemaphore-optionB':
			content = <ClassificationSemaphoreOptionB />;
			break;
		case 'classificationSemaphore-optionC':
			content = <ClassificationSemaphoreOptionC />;
			break;
		case 'outlines':
			content = <Outlines />;
			break;
		case 'photos':
			content = <Photos />;
			break;
		default:
			content = null;
	}

	if (!content && styleDefinitions) {
		content = <StyleBasedLegend styleDefinitions={styleDefinitions} />;
	}

	return content ? (
		<div className="eo4ams-CaseStyle-legend">{content}</div>
	) : null;
};

Legend.propTypes = {
	name: PropTypes.string,
	styleDefinitions: PropTypes.array,
};

export default Legend;
