import PropTypes from 'prop-types';
import Management from './sections/permanentGrassland/Management';
import NoMonitoringReasonSece from './sections/permanentGrassland/NoMonitoringReasonSece';
import SemaphoreWrapper from './sections/permanentGrassland/Semaphore/SemaphoreWrapper';

import HolaPudaIndex from './sections/v7/HolaPuda';
import NDVIIndex from './sections/v3/NDVI';
import NDVIIndexStd from './sections/v7/NDVI';
import PlodinaNaOrnePude from './sections/v3/PlodinaNaOrnePude';
import PravdepodobnostUrceniPlodiny from './sections/v3/PravdepodobnostUrceniPlodiny';
import PocetSeci from './sections/v4/PocetSeci';
import IndexObsahuUhliku from './sections/v5/IndexObsahuUhliku';
import IndexHolePudy from './sections/v5/IndexHolePudy';
import VyskytHolePudyNaParcelePocet from './sections/v5/VyskytHolePudyNaParcelePocet';

import './style.scss';
import MesicniMonitoringSeci from './sections/v4/MesicniMonitoringSeci';
import IndexObsahuUhlikuRelativni from './sections/v5/IndexObsahuUhlikuRelativni';

const Charts = ({name}) => {
	let content;

	switch (name) {
		case 'management':
			content = <Management />;
			break;
		case 'noMonitoringReason-sece':
			content = <NoMonitoringReasonSece />;
			break;
		case 'classificationSemaphore-optionA':
		case 'classificationSemaphore-optionB':
		case 'classificationSemaphore-optionC':
			content = <SemaphoreWrapper name={name} />;
			break;
		case 'v3-orna-puda':
			content = <PlodinaNaOrnePude name={name} />;
			break;
		case 'v3-pravdepodobnost-urceni':
			content = <PravdepodobnostUrceniPlodiny name={name} />;
			break;
		case 'v3-ndvi-04':
		case 'v3-ndvi-05':
		case 'v3-ndvi-06':
		case 'v3-ndvi-07':
		case 'v3-ndvi-08':
		case 'v3-ndvi-09':
			content = <NDVIIndex name={name} />;
			break;
		case 'v4-pocet-seci':
			content = <PocetSeci name={name} />;
			break;
		case 'v4-sec-05':
		case 'v4-sec-06':
		case 'v4-sec-07':
		case 'v4-sec-08':
		case 'v4-sec-09':
			content = <MesicniMonitoringSeci name={name} />;
			break;
		case 'v5-index-uhliku':
			content = <IndexObsahuUhliku name={name} />;
			break;
		case 'v5-index-uhliku-relativni':
			content = <IndexObsahuUhlikuRelativni name={name} />;
			break;
		case 'v5-hola-puda-04':
		case 'v5-hola-puda-05':
		case 'v5-hola-puda-06':
		case 'v5-hola-puda-07':
		case 'v5-hola-puda-08':
		case 'v5-hola-puda-09':
			content = <IndexHolePudy name={name} />;
			break;
		case 'v5-hola-puda-celkem':
			content = <VyskytHolePudyNaParcelePocet name={name} />;
			break;
		case 'v7-hola-puda-04':
		case 'v7-hola-puda-05':
		case 'v7-hola-puda-06':
		case 'v7-hola-puda-07':
		case 'v7-hola-puda-08':
		case 'v7-hola-puda-09':
			content = <HolaPudaIndex name={name} />;
			break;
		case 'v7-ndvi-04':
		case 'v7-ndvi-05':
		case 'v7-ndvi-06':
		case 'v7-ndvi-07':
		case 'v7-ndvi-08':
		case 'v7-ndvi-09':
			content = <NDVIIndexStd name={name} />;
			break;
		default:
			content = null;
	}

	return <>{content}</>;
};

Charts.propTypes = {
	name: PropTypes.string,
};

export default Charts;
