import {createSelector} from 'reselect';
import {Select} from '@gisatcz/ptr-state';

const getActiveCaseConfiguration = createSelector(
	[
		Select.cases.getActiveKey,
		state => Select.app.getConfiguration(state, 'caseLinks'),
	],
	(activeCaseKey, caseLinks) => {
		if (activeCaseKey && caseLinks) {
			return caseLinks[activeCaseKey];
		} else {
			return null;
		}
	}
);

export default {
	getActiveCaseConfiguration,
};
