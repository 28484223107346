import PropTypes from 'prop-types';
import VectorMapTooltip from './VectorMapTooltip';
import './style.scss';

const MapTooltip = ({event, vector}) => {
	const vectorIDs = [];

	const vectorContent = vector.reduce((acc, val, i) => {
		// vector tooltip should come here
		if (!vectorIDs.includes(val.layer.id)) {
			vectorIDs.push(val.layer.id);
			return [
				...acc,
				<VectorMapTooltip
					key={`${i}_${val.layer.props.layerKey || val.layer.props.key}`}
					{...{layer: val, event}}
				></VectorMapTooltip>,
			];
		} else {
			return acc;
		}
	}, []);

	return <div>{vectorContent}</div>;
};

MapTooltip.propTypes = {
	event: PropTypes.object,
	vector: PropTypes.array,
	raster: PropTypes.array,

	children: PropTypes.node,
};

export default MapTooltip;
