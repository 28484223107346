import {connect} from '@gisatcz/ptr-state';

import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = state => {
	const currentRouter = Select.router.getCurrent(state);
	const currentPath = currentRouter?.name;
	const isOpen = currentPath === 'home' || currentPath === 'about';
	const currentSection = currentRouter?.params?.parsedQueryString?.section;
	return {
		activeCaseKey: Select.cases.getActiveKey(state),
		casesGroups: Select.app.getConfiguration(state, 'casesGroups'),
		cases: Select.eo4ams.getCasesForSelect(state),
		isOpen,
		currentSection,
		currentPath,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCaseSelect: () => {
			dispatch(Action.selections.setActiveSelectionFeatureKeysFilterKeys([]));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
