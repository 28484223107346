import {useEffect} from 'react';
import {isNumber as _isNumber} from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useAnalytics} from 'use-analytics';
import {Button} from '@gisatcz/ptr-atoms';

import CaseOverview from './CaseOverview';
import DpbDetail from './DpbDetail';
import './style.scss';

const tabs = [
	{
		key: 'visualization',
		title: 'Témata',
		component: CaseOverview,
	},
	{
		key: 'dpbDetail',
		title: 'Díl půdního bloku (DPB)',
		component: DpbDetail,
	},
];

const getTabsElements = (tabs = [], activeTabKey, onTabClick) => {
	return tabs.map(t => {
		return (
			<Button
				className={`ptr-panel-tap-btn ${
					activeTabKey && activeTabKey === t.key ? ' active' : ''
				}`}
				key={t.key}
				onClick={evt => onTabClick(evt, t.key)}
			>
				<h3 className="ptr-button-caption">{t.title}</h3>
			</Button>
		);
	});
};

const SidePanel = ({
	activeCase,
	activeTabKey,
	className,
	onTabClick,
	defaultActiveTabIndex = 0,
	withoutTabs,
}) => {
	const {track} = useAnalytics();
	const activeTab = activeTabKey && tabs.find(t => t.key === activeTabKey);

	// on tab change
	useEffect(() => {
		track('tab', {
			tab: activeTabKey,
		});
	}, [activeTabKey]);

	// set default tab selected
	useEffect(() => {
		if (
			!activeTab &&
			_isNumber(defaultActiveTabIndex) &&
			tabs?.length &&
			activeCase
		) {
			onTabClick(null, tabs[defaultActiveTabIndex].key);
		}
	}, []);

	const ActiveTabComponent = activeTab?.component || null;
	const classes = classnames('eo4ams-SidePanel ptr-panel', {}, className);

	return (
		<div className={classes}>
			{!withoutTabs ? (
				<>
					<div className="ptr-panel-tabs">
						{getTabsElements(tabs, activeTabKey, onTabClick)}
					</div>
					<div className="ptr-panel-tab-content">
						{ActiveTabComponent ? <ActiveTabComponent /> : null}
					</div>
				</>
			) : (
				<CaseOverview />
			)}
		</div>
	);
};

SidePanel.propTypes = {
	activeCase: PropTypes.string,
	activeTabKey: PropTypes.string,
	className: PropTypes.string,
	defaultActiveTabIndex: PropTypes.number,
	onTabClick: PropTypes.func,
	withoutTabs: PropTypes.bool,
};

export default SidePanel;
