import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import {Provider} from '@gisatcz/ptr-state';
import {isServer} from '@gisatcz/ptr-core';

import createStore from './state/Store';
import {AppWrapper} from './app';

const {store} = createStore();

const ConnectedApp = () => (
	<Provider store={store}>
		<AppWrapper />
	</Provider>
);

function renderApp() {
	const container = document.getElementById('root');
	if (isServer || container.hasChildNodes()) {
		ReactDOM.hydrate(<ConnectedApp />, container);
	} else {
		const root = createRoot(container);
		root.render(<ConnectedApp />);
	}
}

renderApp();
