import React from 'react';
import {ResponsiveBar} from '@nivo/bar';
import ChartTooltip from './ChartTooltip';
import PropTypes from 'prop-types';

const StackedBarChart = ({
	data,
	keys,
	indexBy,
	horizontal,
	colorsDef,
	margin,
	labelsDef,
	valueFormat,
}) => {
	return (
		<ResponsiveBar
			data={data}
			keys={keys}
			indexBy={indexBy}
			margin={margin || {top: 10, right: 0, bottom: 30, left: 0}}
			padding={horizontal ? 0.2 : 0.35}
			valueFormat={valueFormat || ' >-.0%'}
			valueScale={{type: 'linear'}}
			indexScale={{type: 'band', round: true}}
			colors={bar => {
				return colorsDef[bar.indexValue];
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 0,
				tickPadding: 7,
				tickRotation: 0,
			}}
			axisLeft={
				horizontal
					? {
							tickSize: 0,
							tickPadding: 10,
							tickRotation: 0,
					  }
					: null
			}
			enableGridY={false}
			labelSkipHeight={13}
			labelTextColor={{from: 'color', modifiers: [['darker', 3]]}}
			tooltip={props =>
				React.createElement(ChartTooltip, {...props, labelsDef})
			}
			theme={{
				fontSize: 13,
				textColor: '#666666',
			}}
			layout={horizontal ? 'horizontal' : 'vertical'}
		/>
	);
};

StackedBarChart.propTypes = {
	colorsDef: PropTypes.object,
	data: PropTypes.array,
	keys: PropTypes.array,
	labelsDef: PropTypes.object,
	indexBy: PropTypes.string,
	margin: PropTypes.object,
	horizontal: PropTypes.string,
	valueFormat: PropTypes.string,
};

export default StackedBarChart;
