import {commonActionTypes} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';

const specificActionTypes = utils.deepKeyMirror({
	EO4AMS: {
		CONFIGURATION: {
			OPEN: {
				ADD: null,
				REMOVE: null,
			},
			UPDATE: null,
		},
		PERMANENT_GRASSLAND: {
			ADD_MOWING_GRAZING_ATTRIBUTES_BY_DPB_CODE: null,
		},
		MARKERS_DATA: {
			ADD_BY_CASE: null,
		},
	},
});

export default {
	...commonActionTypes,
	...specificActionTypes,
};
