import {useState} from 'react';
import {IconTool} from '@gisatcz/ptr-atoms';
import StackedBarChart from '../../../../../../../common/charts/StackedBarChart';
import ModalWindow from '../../../../../../../common/ModalWindow';
import {
	TableBody,
	TableCell,
	TableCellIcon,
	TableHead,
	TableHeaderCell,
	TableHeaderRow,
	TableRow,
	Table,
} from '../../../../../../../common/Table';

const labels = {
	value: 'Pravděpodobnost',
};

const data = [
	{
		name: 'Řepka ozimá',
		value: 94,
		color: 'rgb(255,255,0)',
	},
	{
		name: 'Ozimé obiloviny',
		value: 93,
		color: 'rgb(115,0,0)',
	},
	{
		name: 'Cukrovka',
		value: 92,
		color: 'rgb(255,170,0)',
	},
	{
		name: 'Pícniny',
		value: 87,
		color: 'rgb(76,230,0)',
	},
	{
		name: 'Jarní obiloviny',
		value: 86,
		color: 'rgb(255,235,175)',
	},
	{
		name: 'Kukuřice',
		value: 84,
		color: 'rgb(255,0,0)',
	},
	{
		name: 'Luskoviny',
		value: 80,
		color: 'rgb(0,77,168)',
	},
	{
		name: 'Mák',
		value: 71,
		color: 'rgb(115,255,223)',
	},
	{
		name: 'Brambory',
		value: 69,
		color: 'rgb(255,190,190)',
	},
	{
		name: 'Ostatní plodiny',
		value: 63,
		color: 'rgb(230,0,169)',
	},
];

const colorsDef = {};
data.forEach(item => (colorsDef[item.name] = '#5AB1DD'));

const PrvdepodobnostUrceniPlodiny = () => {
	const [modalIsOpen, setModalOpen] = useState(false);

	return (
		<div className="eo4ams-Charts">
			<div className="eo4ams-Charts-header">
				<h4>Pravděpodobnost určení plodiny</h4>
				<IconTool
					className="eo4ams-Charts-headerInfo"
					icon={'ri-info'}
					onClick={() => setModalOpen(true)}
				/>
			</div>
			<span className="eo4ams-Charts-shortDescription">
				Míra jistoty v přiřazení dané plodiny/skupiny plodin k dané ZP/části ZP.
			</span>
			<div className="eo4ams-Charts-section full-width">
				<div className="eo4ams-ChartWrapper" style={{height: '16rem'}}>
					<StackedBarChart
						data={[...data].reverse().map(item => {
							return {...item, value: item.value / 100};
						})}
						keys={['value']}
						indexBy="name"
						labelsDef={labels}
						colorsDef={colorsDef}
						horizontal
						margin={{top: 0, right: 30, bottom: 0, left: 120}}
					/>
				</div>
			</div>
			<div className="eo4ams-Charts-section">
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Plodina</TableHeaderCell>
								<TableHeaderCell rightAlign>Pravděpodobnost</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{data.map(item => (
								<TableRow key={item.id}>
									<TableCell>
										<TableCellIcon color={item.color} />
										{item.name}
									</TableCell>
									<TableCell rightAlign>{item.value} %</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
			<ModalWindow
				title="Pravděpodobnost určení plodiny"
				isOpen={modalIsOpen}
				onClose={() => setModalOpen(false)}
				className="eo4ams-Modal"
			>
				<Table>
					<TableHead>
						<TableHeaderRow>
							<TableHeaderCell>Název markeru</TableHeaderCell>
							<TableHeaderCell rightAlign>
								Pravděpodobnost určení plodiny na orné půdě
							</TableHeaderCell>
						</TableHeaderRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Prostorová jednotka</TableCell>
							<TableCell rightAlign>
								Zemědělská parcela / část zemědělské parcely
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Referenční období</TableCell>
							<TableCell rightAlign>Společně s monitoringem plodiny</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Frekvence opakování</TableCell>
							<TableCell rightAlign>Společně s monitoringem plodiny</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Kategorizace markeru</TableCell>
							<TableCell rightAlign>Statistický</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Zdrojová data</TableCell>
							<TableCell rightAlign>Sentinel-1, Sentinel-2, Landsat</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring stavu ZP</TableCell>
							<TableCell rightAlign>
								Přítomnost plodiny / skupiny plodin
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring jevu na ZP</TableCell>
							<TableCell rightAlign>
								Podpora při určení termínu sklizně plodiny
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Způsob výpočtu markeru</TableCell>
							<TableCell rightAlign>Klasifikace družicových dat</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Typ analýzy markeru</TableCell>
							<TableCell rightAlign>Potvrzení deklarované plodiny</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</ModalWindow>
		</div>
	);
};

export default PrvdepodobnostUrceniPlodiny;
