import {useEffect} from 'react';
import propTypes from 'prop-types';
import {includes as _includes} from 'lodash';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import Legend from '../../../../../common/Legend';
import CaseStyleOptions from './CaseStyleOptions/index';

import './style.scss';

const getSelectedStyleKey = (activeStyleKey, styles) => {
	if (styles && activeStyleKey) {
		let selectedStyleKey = null;
		styles.forEach(style => {
			if (style.key === activeStyleKey) {
				selectedStyleKey = style.key;
			}

			const configForStyles = style.config?.configForStyles;
			if (configForStyles && _includes(configForStyles.order, activeStyleKey)) {
				selectedStyleKey = style.key;
			}
		});
		return selectedStyleKey;
	} else {
		return null;
	}
};

const CaseStyle = ({
	title,
	optionsTitle,
	activeStyleKey,
	styles,
	onMount,
	onSelect,
}) => {
	useEffect(() => {
		if (onMount) {
			onMount();
		}
	}, []);

	const selectedStyleKey = getSelectedStyleKey(activeStyleKey, styles);
	const options = [];
	let hasActiveOptionChildren = false;
	let legendName = null;
	let styleDefinitions = null;

	if (styles) {
		styles.forEach(style => {
			const isActive = selectedStyleKey === style.key;
			if (isActive) {
				hasActiveOptionChildren = !!style.config?.configForStyles;
				legendName = style?.config?.name;
				styleDefinitions = style?.data?.definition?.rules?.[0]?.styles;
			}

			options.push(
				<ButtonSwitchOption key={style.key} value={style.key} active={isActive}>
					{style?.data?.nameDisplay}
				</ButtonSwitchOption>
			);
		});
	}

	return (
		<div className="eo4ams-CaseStyle">
			<span className="eo4ams-CaseStyle-label">{title}</span>
			<ButtonSwitch
				ghost
				className="eo4ams-CaseStyle-switch"
				onClick={onSelect}
			>
				{options}
			</ButtonSwitch>
			{!hasActiveOptionChildren ? (
				<Legend name={legendName} styleDefinitions={styleDefinitions} />
			) : null}
			{hasActiveOptionChildren ? (
				<CaseStyleOptions
					title={optionsTitle || 'Vyberte měsíc'}
					styleKey={selectedStyleKey}
				/>
			) : null}
		</div>
	);
};

CaseStyle.propTypes = {
	activeStyleKey: propTypes.string,
	onMount: propTypes.func,
	onSelect: propTypes.func,
	styles: propTypes.array,
	optionsTitle: propTypes.string,
	title: propTypes.string,
};

export default CaseStyle;
