import {connect} from '@gisatcz/ptr-state';
import Presentation from '../CaseStyleOptions/presentation';
import Action from '../../../../../../../state/Action';
import Select from '../../../../../../../state/Select';
import analytics from '../../../../../../../utils/analytics';

const mapStateToProps = (state, ownProps) => {
	const activeMapSetKey = Select.maps.getActiveSetKey(state);

	return {
		styles: Select.eo4ams.getStylesForStyle(state, ownProps.styleKey),
		activeStyleKey: Select.eo4ams.getActiveStyleKey(state, activeMapSetKey),
	};
};

const mapDispatchToProps = dispatch => {
	return () => {
		const componentKey = 'CaseStyleOptions';

		return {
			onMount: () => {
				dispatch(
					Action.styles.useIndexed(null, null, null, 1, 100, componentKey)
				);
			},
			onSelect: styleKey => {
				analytics.track('activeStyle', {
					styleKey: styleKey,
				});

				dispatch(Action.eo4ams.setThematicLayerStyle(styleKey));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
