import './style.scss';
import PropTypes from 'prop-types';

const ChartTooltip = props => {
	const {datum, labelsDef} = props;

	if (datum) {
		const {formattedValue, label, color} = datum;
		return (
			<div className="eo4ams-ChartTooltip">
				<div
					className="eo4ams-ChartTooltip-color"
					style={{background: color}}
				/>
				<div className="eo4ams-ChartTooltip-label">{label}</div>
				<div className="eo4ams-ChartTooltip-value">{formattedValue}</div>
			</div>
		);
	} else if (labelsDef) {
		const {formattedValue, color, id} = props;
		const label = labelsDef[id];
		return (
			<div className="eo4ams-ChartTooltip">
				<div
					className="eo4ams-ChartTooltip-color"
					style={{background: color}}
				/>
				<div className="eo4ams-ChartTooltip-label">{label}</div>
				<div className="eo4ams-ChartTooltip-value">{formattedValue}</div>
			</div>
		);
	} else {
		return null;
	}
};

ChartTooltip.propTypes = {
	id: PropTypes.string,
	color: PropTypes.string,
	datum: PropTypes.object,
	formattedValue: PropTypes.string,
	labelsDef: PropTypes.object,
};

export default ChartTooltip;
