import {useEffect} from 'react';
import propTypes from 'prop-types';
import {useAnalytics} from 'use-analytics';
import AttributesList from './AttributesList';
import MowingGrazingAttributes from './MowingGrazingAttributes';
import DpbPhotos from './DpbPhotos';

import './style.scss';

const DpbDetail = ({
	attributesMetadata,
	onDisplayPhotosPointsChanged,
	onPhotoSelect,
	capturePointsDisplayed,
	componentKey,
	onSelectedFeatureChange,
	mowingGrazingAttributes,
	photos,
	selectedFeatureKey,
}) => {
	const {track} = useAnalytics();

	useEffect(() => {
		if (selectedFeatureKey) {
			// on feature click
			track('dpbDetail', {
				featureKey: selectedFeatureKey,
			});
		}

		if (onSelectedFeatureChange && selectedFeatureKey) {
			onSelectedFeatureChange();
		}
	}, [selectedFeatureKey]);

	return (
		<div className="eo4ams-DpbDetail">
			{selectedFeatureKey ? (
				<>
					<div className="eo4ams-Attributes-container">
						<div>
							<AttributesList componentKey={componentKey} />
							{mowingGrazingAttributes ? (
								<MowingGrazingAttributes
									data={mowingGrazingAttributes}
									attributesMetadata={attributesMetadata}
								/>
							) : null}
						</div>
					</div>
					{photos ? (
						<DpbPhotos
							photos={photos}
							onDisplayPhotosPointsChanged={onDisplayPhotosPointsChanged}
							onPhotoSelect={onPhotoSelect}
							capturePointsDisplayed={capturePointsDisplayed}
						/>
					) : null}
				</>
			) : (
				<p className="eo4ams-DpbDetail-placeholder">
					Vyberte půdní blok v mapě...
				</p>
			)}
		</div>
	);
};

DpbDetail.propTypes = {
	attributesMetadata: propTypes.array,
	capturePointsDisplayed: propTypes.bool,
	onDisplayPhotosPointsChanged: propTypes.func,
	onPhotoSelect: propTypes.func,
	mowingGrazingAttributes: propTypes.object,
	componentKey: propTypes.string,
	onSelectedFeatureChange: propTypes.func,
	selectedFeatureKey: propTypes.string,
	photos: propTypes.object,
};

export default DpbDetail;
