import PropTypes from 'prop-types';

function rgbToHex(R, G, B) {
	return toHex(R) + toHex(G) + toHex(B);
}
function toHex(n) {
	n = parseInt(n, 10);
	if (isNaN(n)) return '00';
	n = Math.max(0, Math.min(n, 255));
	return (
		'0123456789ABCDEF'.charAt((n - (n % 16)) / 16) +
		'0123456789ABCDEF'.charAt(n % 16)
	);
}

export const ProductLabelLegendItem = ({color, name}) => {
	const hexColor = rgbToHex(...color);
	return (
		<div className="mzecrEo4ams21-ProductLabelLegendItem">
			{/* <div style={{background: color}} /> */}
			<div style={{background: `#${hexColor}`}} />
			<span>{name}</span>
		</div>
	);
};

ProductLabelLegendItem.propTypes = {
	color: PropTypes.string,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const VectorLayerToolip = ({
	color,
	properties,
	attributeKey,
	attribute,
	attributeValue,
	nameValue,
}) => {
	const atributeUnit = attribute?.data?.unit;
	let pickValue = nameValue || attributeValue;
	if (atributeUnit === '%') {
		pickValue = `${attributeValue * 100} %`;
	}
	return (
		<div className={'VectorMapTooltip-layer'}>
			<h4>{attribute?.data?.nameDisplay}</h4>
			<div>
				<div>
					<div className="VectorMapTooltip-attribute">Kód DPB: </div>
					<div className="VectorMapTooltip-value">{properties?.NKOD_DPB}</div>
				</div>
				<div>
					{/* <div className="VectorMapTooltip-attribute">{attributeName}: </div>
					<div className="VectorMapTooltip-value">
						</div> */}
					<ProductLabelLegendItem color={color} name={pickValue} />
					{properties?.[attributeKey]}
				</div>
			</div>
		</div>
	);
};

VectorLayerToolip.propTypes = {
	style: PropTypes.object,
	properties: PropTypes.object,
	attributeKey: PropTypes.string,
	attribute: PropTypes.object,
	color: PropTypes.array,
	nameValue: PropTypes.string,
	attributeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default VectorLayerToolip;
