import {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';

import './style.scss';
import enumerations from '../../../../../../data/enumerations';

const months = [
	{
		key: '2021-05',
		value: 'Květen',
	},
	{
		key: '2021-06',
		value: 'Červen',
	},
	{
		key: '2021-07',
		value: 'Červenec',
	},
	{
		key: '2021-08',
		value: 'Srpen',
	},
	{
		key: '2021-09',
		value: 'Září',
	},
	{
		key: '2021-10',
		value: 'Říjen',
	},
];

const mowingAttributes = [
	'MowingReliabilityIndex',
	'PartialMowingIndex',
	'CombinedManagement',
	'RecordStatus',
];

const grazingAttributes = [
	'GrazingReliabilityIndex',
	'CombinedManagement',
	'RecordStatus',
];

const Subsection = ({children, title}) => {
	return (
		<div className="eo4ams-MowingGrazingAttributes-section">
			<h6>{title}</h6>
			{children}
		</div>
	);
};

Subsection.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
};

const AttributesTableHeader = ({attributes, attributesMetadata}) => {
	return (
		<div className="eo4ams-AttributesTable-header">
			<div>Období</div>
			{attributes.map(attribute => {
				const attributeMetadata = attributesMetadata.find(
					metadata => metadata.data?.nameInternal === attribute
				);

				return (
					<div key={attribute} title={attributeMetadata?.data?.nameDisplay}>
						{attributeMetadata?.data?.nameDisplay}
					</div>
				);
			})}
		</div>
	);
};

AttributesTableHeader.propTypes = {
	attributes: PropTypes.array,
	attributesMetadata: PropTypes.array,
};

const AttributesTableRecord = ({children}) => {
	return <div className="eo4ams-AttributesTable-record">{children}</div>;
};

AttributesTableRecord.propTypes = {
	children: PropTypes.node,
};

const AttributesTable = ({children, className}) => {
	const classes = classnames('eo4ams-AttributesTable', {}, className);
	return <div className={classes}>{children}</div>;
};

AttributesTable.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

const MowingRecord = ({data, attributes}) => {
	const {MowingFrom, MowingTo, ...restData} = data;
	const start = moment(MowingFrom).format('DD.MM.');
	const end = moment(MowingTo).format('DD.MM.');

	return (
		<>
			<div>{`${start} - ${end}`}</div>
			{attributes.map(attribute => {
				const value = restData[attribute];
				return (
					<div key={attribute} title={enumerations[attribute]?.[value]}>
						{value || value === 0 ? value : '-'}
					</div>
				);
			})}
		</>
	);
};

MowingRecord.propTypes = {
	data: PropTypes.object,
	attributes: PropTypes.array,
};

const GrazingRecord = ({data, attributes}) => {
	const {GrazingYearMonth, ...restData} = data;
	const month = moment(GrazingYearMonth).format('MM/YYYY');

	return (
		<>
			<div>{`${month}`}</div>
			{attributes.map(attribute => {
				const value = restData[attribute];
				return (
					<div key={attribute} title={enumerations[attribute]?.[value]}>
						{value || value === 0 ? value : '-'}
					</div>
				);
			})}
		</>
	);
};

GrazingRecord.propTypes = {
	data: PropTypes.object,
	attributes: PropTypes.array,
};

const Mowing = ({data, attributesMetadata}) => {
	return (
		<Subsection title="Seče">
			{data ? (
				<AttributesTable className="mowing">
					<AttributesTableHeader
						attributesMetadata={attributesMetadata}
						attributes={mowingAttributes}
					/>
					{data.map((record, i) => (
						<AttributesTableRecord key={i}>
							<MowingRecord data={record} attributes={mowingAttributes} />
						</AttributesTableRecord>
					))}
				</AttributesTable>
			) : (
				<p>Žádné detekce</p>
			)}
		</Subsection>
	);
};

Mowing.propTypes = {
	data: PropTypes.array,
	attributesMetadata: PropTypes.array,
};

const Grazing = ({data, attributesMetadata}) => {
	return (
		<Subsection title="Pastvy">
			{data ? (
				<AttributesTable className="grazing">
					<AttributesTableHeader
						attributesMetadata={attributesMetadata}
						attributes={grazingAttributes}
					/>
					{data.map((record, i) => (
						<AttributesTableRecord key={i}>
							<GrazingRecord data={record} attributes={grazingAttributes} />
						</AttributesTableRecord>
					))}
				</AttributesTable>
			) : (
				<p>Žádné detekce</p>
			)}
		</Subsection>
	);
};

Grazing.propTypes = {
	data: PropTypes.array,
	attributesMetadata: PropTypes.array,
};

const MowingGrazingAttributes = ({data, attributesMetadata}) => {
	const [activeMonth, setMonth] = useState('2021-05');
	const dataForMonth = data[activeMonth];

	return (
		<div className="eo4ams-AttributeSet">
			<h5>Zjištěné pastvy a seče v roce 2021</h5>
			<div className="eo4ams-MowingGrazingAttributes-switch">
				<ButtonSwitch ghost small onClick={key => setMonth(key)}>
					{months.map(month => (
						<ButtonSwitchOption
							className={data[month.key] ? 'with-data' : null}
							key={month.key}
							value={month.key}
							active={month.key === activeMonth}
						>
							{month.value}
						</ButtonSwitchOption>
					))}
				</ButtonSwitch>
			</div>
			<Mowing
				data={dataForMonth?.mowing}
				attributesMetadata={attributesMetadata}
			/>
			<Grazing
				data={dataForMonth?.grazing}
				attributesMetadata={attributesMetadata}
			/>
		</div>
	);
};

MowingGrazingAttributes.propTypes = {
	attributesMetadata: PropTypes.array,
	data: PropTypes.object,
};

export default MowingGrazingAttributes;
