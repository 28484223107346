import {useState} from 'react';
import {IconTool} from '@gisatcz/ptr-atoms';
import ModalWindow from '../../../../../../../common/ModalWindow';
import DonutChart from '../../../../../../../common/charts/DonutChart';
import {
	TableBody,
	TableCell,
	TableCellIcon,
	TableHead,
	TableHeaderCell,
	TableHeaderRow,
	TableRow,
	Table,
} from '../../../../../../../common/Table';

const counts = [
	{
		id: 'ozimeObiloviny',
		label: 'ozimé obiloviny',
		value: 1534,
		color: 'rgb(115,0,0)',
	},
	{
		id: 'jarniObiloviny',
		label: 'jarní obiloviny',
		value: 1441,
		color: 'rgb(255,235,175)',
	},
	{
		id: 'repkaOzima',
		label: 'řepka ozimá',
		value: 537,
		color: 'rgb(255,255,0)',
	},
	{
		id: 'picniny',
		label: 'pícniny',
		value: 709,
		color: 'rgb(76,230,0)',
	},
	{
		id: 'cukrovka',
		label: 'cukrovka',
		value: 197,
		color: 'rgb(255,170,0)',
	},
	{
		id: 'kukurice',
		label: 'kukuřice',
		value: 533,
		color: 'rgb(255,0,0)',
	},
	{
		id: 'brambory',
		label: 'brambory',
		value: 27,
		color: 'rgb(255,190,190)',
	},
	{
		id: 'luskoviny',
		label: 'luskoviny',
		value: 73,
		color: 'rgb(0,77,168)',
	},
	{
		id: 'mak',
		label: 'mák',
		value: 142,
		color: 'rgb(115,255,223)',
	},
	{
		id: 'jinePlodiny',
		label: 'jiné plodiny',
		value: 48,
		color: 'rgb(230,0,169)',
	},
	{
		id: 'plodinaNezjistena',
		label: 'plodina nezjištěna',
		value: 66,
		color: 'rgb(150,150,150)',
	},
];

const sum = 5370;

const PlondinaNaOrnePude = () => {
	const [modalIsOpen, setModalOpen] = useState(false);

	return (
		<div className="eo4ams-Charts">
			<div className="eo4ams-Charts-header">
				<h4>Plodina na orné půdě</h4>
				<IconTool
					className="eo4ams-Charts-headerInfo"
					icon={'ri-info'}
					onClick={() => setModalOpen(true)}
				/>
			</div>
			<span className="eo4ams-Charts-shortDescription">
				Identifikace plodiny/skupiny plodin na dané ZP (či části ZP) dle
				dohodnutého číselníku plodin.
			</span>
			<div className="eo4ams-Charts-section chart-table">
				<div className="eo4ams-ChartWrapper" style={{height: '12rem'}}>
					<DonutChart
						data={counts.map(item => {
							return {...item, value: item.value / sum};
						})}
						withoutLegend
						margin={{top: 10, right: 30, bottom: 10, left: 10}}
					/>
				</div>
				<div className="eo4ams-TableWrapper">
					<Table>
						<TableHead>
							<TableHeaderRow>
								<TableHeaderCell>Plodina</TableHeaderCell>
								<TableHeaderCell rightAlign>
									Počet půdních bloků
								</TableHeaderCell>
							</TableHeaderRow>
						</TableHead>
						<TableBody>
							{counts.map(item => (
								<TableRow key={item.id}>
									<TableCell>
										<TableCellIcon color={item.color} />
										{item.label}
									</TableCell>
									<TableCell rightAlign>{item.value}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>
			<ModalWindow
				title="Plodina na orné půdě"
				isOpen={modalIsOpen}
				onClose={() => setModalOpen(false)}
				className="eo4ams-Modal"
			>
				<Table>
					<TableHead>
						<TableHeaderRow>
							<TableHeaderCell>Název markeru</TableHeaderCell>
							<TableHeaderCell rightAlign>Plodina na orné půdě</TableHeaderCell>
						</TableHeaderRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Prostorová jednotka</TableCell>
							<TableCell rightAlign>
								Zemědělská parcela / část zemědělské parcely
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Referenční období</TableCell>
							<TableCell rightAlign>
								Podle požadavků (od začátku vegetačního období)
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Frekvence opakování</TableCell>
							<TableCell rightAlign>Podle požadavků (min. 1 měsíc)</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Kategorizace markeru</TableCell>
							<TableCell rightAlign>Tematický</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Zdrojová data</TableCell>
							<TableCell rightAlign>Sentinel-1, Sentinel-2, Landsat</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring stavu ZP</TableCell>
							<TableCell rightAlign>
								Přítomnost plodiny / skupiny plodin
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Monitoring jevu na ZP</TableCell>
							<TableCell rightAlign>
								Podpora při určení termínu sklizně plodiny
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Způsob výpočtu markeru</TableCell>
							<TableCell rightAlign>Klasifikace družicových dat</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Typ analýzy markeru</TableCell>
							<TableCell rightAlign>Potvrzení deklarované plodiny</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</ModalWindow>
		</div>
	);
};

export default PlondinaNaOrnePude;
